import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface IndiceInterface extends ReferenceInterface {
    label: string;
    devise_code: string;
}

class Indice {
    all() {
        return parseResponse<Array<IndiceInterface>>(fetch.get(`reference/indice`));
    }
}

export default new Indice();
