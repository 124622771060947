import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CleAnalytiqueInterface extends ReferenceInterface {
    data: Array<any>;
}

class CleAnalytique {
    all(clientId: string) {
        return parseResponse<Array<CleAnalytiqueInterface>>(fetch.get(`reference/cle_analytique/${clientId}`));
    }
}

export default new CleAnalytique();
