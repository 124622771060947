import React from "react";

export interface FieldFeedbackProps {
    className?: string;
}

const FieldFeedback: React.FC<FieldFeedbackProps> = ({ children, className = "field__feeback" }) => {
    return <div className={`${className}`}>{children}</div>;
};

export default FieldFeedback;
