import React, { useContext } from "react";
import { Form } from "react-final-form";
import { AuthContext, IntlContext, ClientContext } from "context";
import { SelectCustom } from "components/Form/Field";
import AutoSubmit from "components/Form/AutoSubmit";
import Logo from "components/Display/Logo";

const TopbarSwitchClient: React.FC = () => {
    const intl = useContext(IntlContext);
    const auth = useContext(AuthContext);
    const clients = auth.account?.clients;
    const { currentClient, setCurrentClient } = useContext(ClientContext);

    if (!clients || !clients.length) {
        return null;
    }

    return (
        <div className="appbar__toolbar__client">
            <div className="appbar__toolbar__client-logo">
                {currentClient.logo_id && <Logo id={currentClient.logo_id} />}
            </div>
            {clients.length === 1 && <div className="appbar__toolbar__client-name">{currentClient.nom}</div>}
            {clients.length > 1 && (
                <div className="appbar__toolbar__client-switch">
                    <Form
                        initialValues={{
                            client_id: currentClient.id,
                        }}
                        onSubmit={(data: any) => {
                            setCurrentClient(data.client_id);
                        }}
                        render={({ handleSubmit, dirty }) => (
                            <form onSubmit={handleSubmit}>
                                <AutoSubmit debounce={600} />
                                <SelectCustom
                                    inputProps={{
                                        required: true,
                                        name: "client_id",
                                        options: clients.map((item) => {
                                            return {
                                                label: item.nom,
                                                value: item.id,
                                            };
                                        }),
                                    }}
                                    labelProps={{
                                        label: intl.formatMessage("generic.menu.switch.client"),
                                        requiredSymbole: "",
                                    }}
                                />
                            </form>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default TopbarSwitchClient;
