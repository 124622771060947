import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface BanqueInterface extends ReferenceInterface {
    nom_usage: string;
    nom_usage_normalized: string;
    raison_sociale: string;
    raison_sociale_normalized: string;
    groupe: string | null;
    lei: string | null;
    pays_code: string | null;
    categorie_code: string | null;
    adresse: string | null;
    email: string | null;
    website: string | null;
    telephones: string | null;
    logo_id: string | null;
    created_at: string;
    updated_at: string;
}

class Banque {
    all() {
        return parseResponse<Array<BanqueInterface>>(fetch.get(`reference/banque`));
    }
}

export default new Banque();
