import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CategorieBanqueInterface extends ReferenceInterface {}

class CategorieBanque {
    all(languageCode: string) {
        return parseResponse<Array<CategorieBanqueInterface>>(fetch.get(`reference/categorie_banque/${languageCode}`));
    }
}

export default new CategorieBanque();
