import React from "react";

export interface FieldGroupProps {
    className?: string;
    errorClassName?: string;
    error?: boolean;
}

const FieldGroup: React.FC<FieldGroupProps> = ({
    children,
    className = "field",
    errorClassName = "error",
    error = false,
}) => {
    return <div className={`${className} ${error ? errorClassName : ""}`}>{children}</div>;
};

export default FieldGroup;
