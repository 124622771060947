import React, { useEffect, useState } from "react";
import { TextField, SelectCustom } from "components/Form/Field";
import AmountField from "components/Form/Field/Amount"; // else import error...

const customComponents: any = {
    TextField: TextField,
    SelectCustom: SelectCustom,
    AmountField: AmountField,
};

const FieldTableTd: React.FC<{
    name: string;
    index: number;
    field: any;
    errorMessage: any;
    saisieError?: Array<string>;
}> = ({ name, index, field, errorMessage, saisieError }) => {
    const CustomComponent = customComponents[field.component];
    const [formError, setFormError] = useState<boolean>(false);

    useEffect(() => {
        const hasError: boolean =
            saisieError !== undefined ||
            (errorMessage !== undefined &&
                errorMessage[index] !== undefined &&
                errorMessage[index][field.key] !== undefined) ||
            (errorMessage?.props?.error !== undefined &&
                errorMessage?.props?.error[index] !== undefined &&
                errorMessage?.props?.error[index][field.key] !== undefined);

        if (hasError !== formError) {
            setFormError(hasError);
        }
    }, [saisieError, errorMessage, formError, index, field]);

    const fieldProps: any = Object.assign(
        {
            name: `${name}.${field.key}`,
        },
        { ...field }
    );

    return (
        <td className={formError ? "error" : ""}>
            {CustomComponent !== undefined && (
                <CustomComponent
                    inputProps={fieldProps}
                    labelProps={{
                        label: field.label,
                    }}
                    adornmentEnd={field?.adornmentend}
                    adornmentStart={field?.adornmentstart}
                />
            )}
            {CustomComponent === undefined &&
                field.component !== undefined &&
                field.component({
                    name: name,
                    index: index,
                    field: fieldProps,
                }).props.children}
        </td>
    );
};

export default FieldTableTd;
