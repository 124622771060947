export const applicationTaux = [
    "PROPORTIONNEL",
    "ACTUALISE",
    "ZC_HORS_MARGE",
    "ZC_AVEC_MARGE",
    "CAPITALISE_PROP_HORS_MARGE",
    "CAPITALISE_PROP_AVEC_MARGE",
    "CAPITALISE_ZC_HORS_MARGE",
    "CAPITALISE_ZC_AVEC_MARGE",
];

export const applicationTauxNotBase1J = ["PROPORTIONNEL", "ACTUALISE", "ZC_HORS_MARGE", "ZC_AVEC_MARGE"];

export const calculTaux = [
    "SIMPLE",
    "MOYENNE_ARITHMETIQUE",
    "MOYENNE_PONDEREE",
    "CAPITALISE_PROP_HORS_MARGE",
    "CAPITALISE_PROP_AVEC_MARGE",
    "CAPITALISE_ZC_HORS_MARGE",
    "CAPITALISE_ZC_AVEC_MARGE",
];

export const calculTauxNotBase1J = ["SIMPLE", "MOYENNE_ARITHMETIQUE", "MOYENNE_PONDEREE"];

export const categoryFinancement = [
    "FINANCEMENT_BANCAIRE",
    "FINANCEMENT_BANCAIRE-SYNDIQUE",
    "COMMERCIAL_PAPER",
    "CREDIT_BAIL",
    "OBLIGATION",
    "PLACEMENT_PRIVE",
    "CESSION_CREANCE",
    "FINANCEMENT_PUBLIC",
    "HYBRIDE",
    // "EMPRUNT_INTRA-GROUPE",
    // "AUTRES",
];

export const uploadDocuments = [
    ".xls",
    ".xlsx",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
];

export const listBaseCalcul = [
    "E_360",
    "E_365-ISDA",
    "E_365-ICMA",
    "E_E-ISDA",
    "E_E-ICMA",
    "E_E-FBF",
    "30_360-ISDA",
    "30_360-ICMA",
    "30E_360",
    "30E_360-ISDA",
    "30_360-US",
    "BUS_252",
    "NL_365",
    "NL_360",
    "1_1",
    "30_360-BMA",
    "30E+_360",
];

export const listBaseCalcul1JApplicationTaux = ["E_360", "E_365-ISDA", "BUS_252"];
export const listBaseCalcul1JCalculTaux = [
    "E_360",
    "E_365-ISDA",
    "E_365-ICMA",
    "E_E-ISDA",
    "E_E-FBF",
    "30_360-ISDA",
    "30_360-ICMA",
    "30E_360",
    "30E_360-ISDA",
    "30_360-US",
    "BUS_252",
    "NL_365",
    "NL_360",
    "30_360-BMA",
    "30E+_360",
];

export const listBaseCalculSociete = ["BUS_252", "E_360", "E_E-ISDA", "E_365-ISDA", "30_360-ISDA"];

export const categoryPlacement = ["PLACEMENT_INTRA-GROUPE", "PLACEMENT_BANCAIRE"];

export const contratSens = [
    "FINANCEMENT",
    // "PLACEMENT",
];

export const ligneStatus = ["PRE_SAISIE", "VALIDE_FRONT", "VALIDE_BACK"];

export const ligneClassification = ["COURT_TERME", "MOYEN_TERME", "LONG_TERME"];

export const ajustementJO = ["NA", "MF", "MFA"];

export const listTauxType = ["FIXE", "VARIABLE", "STRUCTURE", "INTERPOLE"];

export const listPeriodicite = ["Q", "28J", "1M", "3M", "6M", "12M", "ZC"];

export const listPeriodiciteSmall = ["1M", "3M", "6M", "12M"];

export const listAmortissementType = ["IN_FINE", "LINEAIRE", "ANNUITE_CONSTANTE", "PROGRESSIF", "PERSONNALISE"];

export const listMinMaxSurVariable = ["INDICE", "INDICE_MARGE"];
export const listMinMaxSurInterpole = ["TAUX_INTERPOLE_HORS_MARGE", "TAUX_INTERPOLE_AVEC_MARGE", "CHAQUE_INDICE"];
export const listMinMaxSurStructure = ["TAUX_STRUCTURE_HORS_MARGE", "TAUX_STRUCTURE_AVEC_MARGE"];

export const listFraisAmortissementType = ["NON", "LINEAIRE", "PERSONNALISE"];
export const listCommissionsType = ["SIMPLE", "POURCENTAGE_MARGE", "INDEXEE"];
export const listCommissionPaiementType = ["DECALAGE", "JOUR_MOIS_SUIVANT"];

export const ratiosLigneTirable = ["POURCENTAGE_UTIL", "POURCENTAGE_NON_UTIL"];

export const listDecalageType = ["JO", "JC"];

export const remboursementIndemniteTypes = ["ACTUARIELLE", "FORFAITAIRE"]; // @TODO : check coming specs
export const remboursementIndemniteValeurs = ["POURCENTAGE", "MOIS", "MONTANT"]; // @TODO : check coming specs
export const listTemporalite = ["DATE_ANNIVERSAIRE", "ANY"];
