import React, { Suspense } from "react";

import { Topbar, ErrorBoundary } from "./components";

interface Props {
    sidebar?: React.ComponentClass<any, any> | React.FunctionComponent<any>;
}
const Main: React.FC<Props> = (props) => {
    const Sidebar = props.sidebar;
    return (
        <React.Fragment>
            <Topbar />
            <div className="root-container">
                {Sidebar && <Sidebar />}
                <main className="main">
                    <ErrorBoundary>
                        <Suspense fallback={"loading"}>{props.children}</Suspense>
                    </ErrorBoundary>
                </main>
            </div>
        </React.Fragment>
    );
};

export default Main;
