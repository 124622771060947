import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ContrepartieInterface extends ReferenceInterface {
    logo_id: string | null;
}

class Contrepartie {
    all(clientId: string) {
        return parseResponse<Array<ContrepartieInterface>>(fetch.get(`reference/contrepartie/${clientId}`));
    }
}

export default new Contrepartie();
