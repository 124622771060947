import React from "react";
import FormSelectCustom, { FormSelectCustomProps } from "ui/Form/SelectCustom";

import { Field, UseFieldConfig } from "react-final-form";

type Props = FormSelectCustomProps & UseFieldConfig<string>;

const SelectCustomField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    infoMessage,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormSelectCustom
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        adornmentStart={adornmentStart}
                        adornmentEnd={adornmentEnd}
                        inputProps={Object.assign({}, input, inputProps)}
                        infoMessage={infoMessage}
                    />
                );
            }}
        </Field>
    );
};

export default SelectCustomField;
