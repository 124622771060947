import React, { useState } from "react";
import moment from "moment";
import dateFormatIsValid from "helpers/form/dateFormatIsValid";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Input, { InputProps } from "./Element/Input";

import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface FieldDateProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: InputProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const FieldDate: React.FC<FieldDateProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
}) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    const min = moment().subtract(100, "years").format("YYYY-MM-DD");
    const max = moment().add(100, "years").format("YYYY-MM-DD");
    const originalOnBlur = inputProps.onBlur;
    const originalOnKeyUp = inputProps.onKeyUp;

    inputProps.onBlur = (e: any) => {
        if (originalOnBlur !== undefined) {
            originalOnBlur(e);
        }
        setHasError(!dateFormatIsValid(e.target.value));
    };

    inputProps.onKeyUp = (e: any) => {
        if (originalOnKeyUp !== undefined) {
            originalOnKeyUp(e);
        }
        setHasError(!dateFormatIsValid(e.target.value));
    };

    inputProps.maxLength = 10;
    inputProps.min = inputProps.min === "" || inputProps.min === undefined ? min : inputProps.min;
    inputProps.max = inputProps.max === "" || inputProps.max === undefined ? max : inputProps.max;

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    return (
        <FieldGroup error={error || hasError} {...fieldGroupProps}>
            <FieldControl disabled={disabled} error={error || hasError} {...fieldControlProps}>
                {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}

                <Input disabled={disabled} {...inputProps} />

                {labelProps !== undefined && (
                    <Label
                        htmlFor={inputProps.id}
                        disabled={disabled}
                        error={error}
                        required={required}
                        {...labelProps}
                    >
                        {labelProps.label}
                    </Label>
                )}

                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
            </FieldControl>

            {infoMessage !== undefined && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FieldDate;
