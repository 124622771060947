import React from "react";
import { UseFieldConfig } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FieldTable, { FieldTableProps } from "ui/Form/Table";

type Props = FieldTableProps & UseFieldConfig<any[]>;

const TableField: React.FC<Props> = ({ header, footer, inputProps, saisieErrors, ...fieldProps }) => {
    return (
        <FieldArray name={inputProps.name} {...fieldProps}>
            {({ fields, meta }) => {
                return (
                    <FieldTable
                        fields={fields}
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.submitError}
                        saisieErrors={saisieErrors}
                        header={header}
                        footer={footer}
                        inputProps={Object.assign({}, inputProps)}
                    />
                );
            }}
        </FieldArray>
    );
};

export default TableField;
