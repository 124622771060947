import fetch, { parseResponse } from "../fetch";

interface SocieteInterface {
    id: string;
    nom_usage: string;
    raison_sociale: string;
    pourcentage: number;
}

export interface PerimetreInterface {
    id: string;
    nom: string;
    devise: string;
    date_anniversaire: string;
    societes: Array<SocieteInterface>;
}

class Perimetre {
    list(clientId: string) {
        return parseResponse<Array<PerimetreInterface>>(fetch.get(`analyse/perimetres/${clientId}`));
    }
}

export default new Perimetre();
