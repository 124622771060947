import React from "react";
import { NavLink } from "react-router-dom";
import ProtectedComponent from "components/Secured/ProtectedComponent";

interface Props {
    to: string;
    className?: string;
    activeClassName?: string;
    activeStyle?: React.CSSProperties;
    exact?: boolean;
    strict?: boolean;
    capability?: string;
}

const TopbarNavLink: React.FC<Props> = ({
    to,
    className = "appbar__item",
    activeClassName = "appbar__item appbar__item--active",
    strict,
    exact,
    children,
    capability,
}) => {
    return (
        <ProtectedComponent capability={capability}>
            <NavLink to={to} className={className} activeClassName={activeClassName} strict={strict} exact={exact}>
                {children}
            </NavLink>
        </ProtectedComponent>
    );
};

export default TopbarNavLink;
