import React, { useContext } from "react";
import { IntlContext } from "context";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Checkbox, { CheckboxProps } from "./Element/Checkbox";
import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";

export interface FormCheckboxProps {
    error?: boolean;
    errorMessage?: any;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: CheckboxProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
    error = false,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
}) => {
    const intl = useContext(IntlContext);
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <FieldControl className={"field-control-checkbox"} error={error} disabled={disabled} {...fieldControlProps}>
                <Checkbox disabled={disabled} {...inputProps} />
            </FieldControl>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            {error && errorMessage?.props?.error?.code && (
                <Feedback className="field__feedback error">
                    {errorMessage?.props?.error?.code &&
                        intl.formatMessage(`error.${errorMessage?.props?.error?.code}`)}
                    {!errorMessage?.props?.error?.code && errorMessage !== undefined && errorMessage}
                </Feedback>
            )}
        </FieldGroup>
    );
};

export default FormCheckbox;
