import React from "react";
import { FormSpy, FormSpyProps, FormSpyRenderProps } from "react-final-form";

interface Props extends FormSpyRenderProps {
    debounce: number;
    forceNotChanged?: boolean;
}

interface State {
    values: HashMap<any>;
    initialValues: HashMap<any>;
}

class AutoSubmit extends React.Component<Props, State> {
    private timeout?: NodeJS.Timeout;

    private promise?: Promise<any>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            initialValues: props.initialValues,
            values: props.values,
        };
    }

    componentDidUpdate() {
        const { debounce, dirty, submitting, initialValues } = this.props;

        const hasChanged = Object.keys(initialValues).reduce((result, key) => {
            if (initialValues[key] !== this.state.initialValues[key]) {
                return true;
            }
            return result;
        }, false);

        if (hasChanged) {
            this.setState({
                values: { ...this.props.initialValues },
                initialValues: { ...this.props.initialValues },
            });
        }

        if (!dirty || submitting) {
            return;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.save();
        }, debounce);
    }

    save = async () => {
        if (this.promise) {
            await this.promise;
        }

        const values = this.props.values;

        const hasChanged = Object.keys(values).reduce((result, key) => {
            if (values[key] !== this.state.values[key]) {
                return true;
            }
            return result;
        }, false);

        if (hasChanged || this.props.forceNotChanged) {
            this.setState({ values: { ...this.props.values } });
            this.promise = this.props.form.submit();
            await this.promise;
            delete this.promise;
        }
    };

    render() {
        return null;
    }
}

interface HocProps extends FormSpyProps {
    debounce: number;
    forceNotChanged?: boolean;
}

const AutoSubmitHOC: React.FC<HocProps> = ({ debounce, forceNotChanged, ...props }) => {
    return (
        <FormSpy {...props} subscription={{ values: true, dirty: true, submitting: true, initialValues: true }}>
            {(props) => {
                return <AutoSubmit debounce={debounce} forceNotChanged={forceNotChanged} {...props} />;
            }}
        </FormSpy>
    );
};
export default AutoSubmitHOC;
