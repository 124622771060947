import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CalendarInterface extends ReferenceInterface {}

class Calendar {
    all(languageCode: string) {
        return parseResponse<Array<CalendarInterface>>(fetch.get(`reference/calendar/${languageCode}`));
    }
}

export default new Calendar();
