import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "context";

const AppbarDropdown: React.FC<{ buttonContent: React.ReactNode; onClick?: () => void }> = ({
    children,
    buttonContent,
    onClick,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const refMenu = useRef<HTMLDivElement>(null);
    const auth = useContext(AuthContext);

    const onOuterClick = useCallback((event: MouseEvent) => {
        const menu = refMenu?.current;
        const target = event.target as Node;
        const intElems = ["A", "BUTTON"];
        if (intElems.indexOf(target.nodeName) >= 0) {
            window.removeEventListener("click", onOuterClick);
            setDropdownOpen(false);
        }
        if (menu && !menu.contains(target)) {
            window.removeEventListener("click", onOuterClick);
            setDropdownOpen(false);
        }
    }, []);

    useEffect(() => {
        if (dropdownOpen) {
            window.addEventListener("click", onOuterClick);
        } else {
            window.removeEventListener("click", onOuterClick);
        }
        return () => {
            window.removeEventListener("click", onOuterClick);
        };
    }, [dropdownOpen, onOuterClick]);

    let childsCount = 0;
    React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) {
            childsCount++;
            return;
        }

        if (child.props.capability && !auth.isAllowed(child.props.capability)) {
            return;
        }
        childsCount++;
    });

    if (childsCount === 0) {
        return null;
    }

    return (
        <div className="appbar-dropdown" ref={refMenu}>
            <button type="button" className="appbar-dropdown__button" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {buttonContent}
                <Icon
                    className={"svg-inline--active margin-left--1 " + (dropdownOpen ? "up" : "")}
                    icon={{ prefix: "fab", iconName: "caret-down" }}
                />
            </button>
            <div className={`appbar-dropdown__menu ${dropdownOpen ? "appbar-dropdown__menu--show" : ""}`}>
                {children}
            </div>
        </div>
    );
};

export default AppbarDropdown;
