import React, { useContext } from "react";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import Button from "ui/Button";

const Actions: React.FC<{
    fields: any;
    index: number;
    isFirst: boolean;
    isLast: boolean;
    defaultValues: any;
    onRowMove?: (oldIndex: number, newIndex?: number) => void;
    hideMove?: boolean;
    allowRemoveAll?: boolean;
}> = ({ fields, index, isFirst, isLast, defaultValues, onRowMove, hideMove, allowRemoveAll }) => {
    const intl = useContext(IntlContext);

    return (
        <table className="field__table__actions">
            <tbody>
                <tr>
                    {!hideMove && (
                        <React.Fragment>
                            <td>
                                {!isFirst && (
                                    <Button
                                        className="btn btn--link"
                                        onClick={() => {
                                            fields.move(index, index - 1);

                                            if (onRowMove !== undefined) {
                                                onRowMove(index, index - 1);
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon={{ prefix: "fas", iconName: "arrow-up" }}
                                            title={intl.formatMessage("generic.field_table.data.up")}
                                        />
                                    </Button>
                                )}
                            </td>
                            <td>
                                {!isLast && (
                                    <Button
                                        className="btn btn--link"
                                        onClick={() => {
                                            fields.move(index, index + 1);

                                            if (onRowMove !== undefined) {
                                                onRowMove(index, index + 1);
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon={{ prefix: "fas", iconName: "arrow-down" }}
                                            title={intl.formatMessage("generic.field_table.data.down")}
                                        />
                                    </Button>
                                )}
                            </td>
                        </React.Fragment>
                    )}
                    <td>
                        {((!isFirst && fields.value.length > 1) || (isFirst && allowRemoveAll === true)) && (
                            <Button
                                className="btn btn--link"
                                onClick={() => {
                                    fields.remove(index);

                                    if (onRowMove !== undefined) {
                                        onRowMove(index);
                                    }
                                }}
                            >
                                <Icon
                                    icon={{ prefix: "fas", iconName: "times" }}
                                    title={intl.formatMessage("generic.field_table.data.delete")}
                                />
                            </Button>
                        )}
                    </td>
                    <td>
                        {isLast && (
                            <Button className="btn btn--link" onClick={() => fields.push(defaultValues)}>
                                <Icon
                                    icon={{ prefix: "fas", iconName: "plus" }}
                                    title={intl.formatMessage("generic.field_table.data.add")}
                                />
                            </Button>
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default Actions;
