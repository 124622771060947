import React from "react";

export interface InputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    name: string;
}

const Input: React.FC<InputProps> = ({
    className = "field-control__input",
    autoComplete = "off",
    type = "text",
    placeholder = " ",
    ...props
}) => {
    return <input className={className} autoComplete={autoComplete} type={type} placeholder={placeholder} {...props} />;
};

export default Input;
