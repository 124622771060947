import React from "react";

type Props = {
    message: string | null;
};

const Loader: React.FC<Props> = ({ message }) => {
    return (
        <div className="loader-wrapper">
            <div className="loader" />
            <div className="text-center">{message && <p className="loader-text">{message}</p>}</div>
        </div>
    );
};

export default Loader;
