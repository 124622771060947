import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

const SidebarIcon: React.FC<{ iconName: any; iconPrefix?: any }> = ({ iconName, iconPrefix }) => {
    return (
        <span className="sidebar__link_icon">
            <Icon icon={{ prefix: iconPrefix ?? "fab", iconName: iconName }} />
        </span>
    );
};

export default SidebarIcon;
