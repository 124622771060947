import Node from "./Node";

class Acl {
    private rootNode: Node;

    constructor() {
        this.rootNode = new Node("root", true);
    }

    allow(capability: string) {
        this.rootNode.allow(capability.split("."));
    }

    isAllowed(capability: string) {
        return this.rootNode.isAllowed(capability.split("."));
    }
}

export default Acl;
