import React from "react";

enum FlashTypes {
    success = "success",
    error = "error",
    warning = "warning",
}

export interface FlashMessage {
    id: number;
    type: FlashTypes;
    duration: number;
    text: string;
}

export interface FlashActionProviderInterface {
    addSuccess: (text: string, duration?: number) => void;
    addError: (text: string, duration?: number) => void;
    addWarning: (text: string, duration?: number) => void;
    removeFlashMessage: (flashMessageId: number) => void;
}

export interface FlashMessageStateInterface {
    messages: Array<FlashMessage>;
    actions: FlashActionProviderInterface;
}

const FlashActionsContext = React.createContext<FlashActionProviderInterface>({
    addSuccess: () => {},
    addError: () => {},
    addWarning: () => {},
    removeFlashMessage: () => {},
});

const Consumer = FlashActionsContext.Consumer;

interface Props {
    FlashComponent: React.ComponentType<any>;
}

let nextFlashMessageId = 1;

class Provider extends React.Component<Props, FlashMessageStateInterface> {
    constructor(props: Props) {
        super(props);
        this.state = {
            messages: [],
            actions: {
                addSuccess: this.addSuccess,
                addError: this.addError,
                addWarning: this.addWarning,
                removeFlashMessage: this.removeFlashMessage,
            },
        };
    }

    removeFlashMessage = (flashMessageId: number) => {
        this.setState({
            messages: this.state.messages.filter((f) => flashMessageId !== f.id),
        });
    };

    addSuccess = (text: string, duration: number = 10000) => {
        this.addFlashMessageOfType(FlashTypes.success, duration, text);
    };

    addError = (text: string, duration: number = 10000) => {
        this.addFlashMessageOfType(FlashTypes.error, duration, text);
    };

    addWarning = (text: string, duration: number = 10000) => {
        this.addFlashMessageOfType(FlashTypes.warning, duration, text);
    };

    addFlashMessageOfType = (type: FlashTypes, duration: number, text: string) => {
        const id = nextFlashMessageId++;
        const flashMessage: FlashMessage = { id, type, duration, text };
        this.setState({
            messages: [...this.state.messages, flashMessage],
        });
    };

    render() {
        const { FlashComponent } = this.props;
        return (
            <React.Fragment>
                <FlashComponent flash={this.state} />
                <FlashActionsContext.Provider value={this.state.actions}>
                    {this.props.children}
                </FlashActionsContext.Provider>
            </React.Fragment>
        );
    }
}

export { Provider, Consumer, FlashActionsContext as Context };
