import React, { useContext, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { IntlContext } from "context";
import SidebarDropdown from "./Dropdown";
import NavLink from "./NavLink";
import LogoForex from "assets/images/logo-forex-vertical-tag.png";

const Sidebar: React.FC = () => {
    const intl = useContext(IntlContext);
    const match = useRouteMatch();
    const [openedDropdown, setOpenedDropdown] = useState(match.url);

    const toggleDropdown = (item: string) => {
        setOpenedDropdown(item);
    };

    return (
        <nav className="sidebar">
            <ul className="sidebar__nav">
                <SidebarDropdown
                    link="/analyse/"
                    capability="analyse.*"
                    icon="signal"
                    title={intl.formatMessage("generic.sidebar.analyse")}
                    toggleDropdown={toggleDropdown}
                    current={openedDropdown}
                >
                    <NavLink to={"/analyse/dashboard"} capability="analyse.dashboard">
                        {intl.formatMessage("generic.sidebar.analyse.dashboard")}
                    </NavLink>
                    <NavLink to={"/analyse/preteur/"} capability="analyse.preteur">
                        {intl.formatMessage("generic.sidebar.analyse.preteur")}
                    </NavLink>
                    <NavLink to={"/analyse/nature_financement/"} capability="analyse.nature_financement">
                        {intl.formatMessage("generic.sidebar.analyse.nature_financement")}
                    </NavLink>
                    <NavLink to={"/analyse/taux/"} capability="analyse.type_taux">
                        {intl.formatMessage("generic.sidebar.analyse.taux")}
                    </NavLink>
                    <NavLink to={"/analyse/devise/"} capability="analyse.devise">
                        {intl.formatMessage("generic.sidebar.analyse.devise")}
                    </NavLink>
                    <NavLink to={"/analyse/taux_financement/"} capability="analyse.taux_financement">
                        {intl.formatMessage("generic.sidebar.analyse.taux_financement")}
                    </NavLink>
                    <NavLink to={"/analyse/frais_financiers/"} capability="analyse.frais_financiers">
                        {intl.formatMessage("generic.sidebar.analyse.frais_financiers")}
                    </NavLink>
                    <NavLink to={"/analyse/cash_flow/"} capability="analyse.cash_flow">
                        {intl.formatMessage("generic.sidebar.analyse.cash_flow")}
                    </NavLink>
                    <NavLink to={"/analyse/gap_liquidite/"} capability="analyse.gap_liquidite">
                        {intl.formatMessage("generic.sidebar.analyse.gap_liquidite")}
                    </NavLink>
                    <NavLink to={"/analyse/nn1/"} capability="analyse.nn1">
                        {intl.formatMessage("generic.sidebar.analyse.nn1")}
                    </NavLink>
                </SidebarDropdown>
                <SidebarDropdown
                    link="/financement/"
                    capability="financement.*"
                    icon="list"
                    title={intl.formatMessage("generic.sidebar.financement")}
                    toggleDropdown={toggleDropdown}
                    current={openedDropdown}
                >
                    <NavLink to={"/financement/saisie/"} capability="financement.saisie">
                        {intl.formatMessage("generic.sidebar.financement.saisie")}
                    </NavLink>
                    <NavLink to={"/financement/liste/"} capability="financement.liste">
                        {intl.formatMessage("generic.sidebar.financement.list")}
                    </NavLink>
                    <NavLink to={"/financement/besoin/"} capability="financement.besoin">
                        {intl.formatMessage("generic.sidebar.financement.besoin")}
                    </NavLink>
                    <NavLink to={"/financement/echeancier_global/"} capability="financement.echeancier">
                        {intl.formatMessage("generic.sidebar.financement.echeancier")}
                    </NavLink>
                    <NavLink to={"/financement/calendrier/"} capability="financement.calendrier">
                        {intl.formatMessage("generic.sidebar.financement.calendrier")}
                    </NavLink>
                    <NavLink to={"/financement/icne/"} capability="financement.icne">
                        {intl.formatMessage("generic.sidebar.financement.icne")}
                    </NavLink>
                </SidebarDropdown>
                <SidebarDropdown
                    link="/donnees_marches/"
                    capability="financement.*"
                    icon="chart-line"
                    iconPrefix="fas"
                    title={intl.formatMessage("generic.sidebar.donnees_marches")}
                    toggleDropdown={toggleDropdown}
                    current={openedDropdown}
                    to={"/donnees_marches/"}
                ></SidebarDropdown>
                <SidebarDropdown
                    link="/admin_client/"
                    capability="admin_client.*"
                    icon="cog"
                    title={intl.formatMessage("generic.sidebar.admin_client")}
                    toggleDropdown={toggleDropdown}
                    current={openedDropdown}
                >
                    <NavLink to={"/admin_client/societe/"} capability="admin_client.societe.all">
                        {intl.formatMessage("generic.sidebar.admin_client.societe")}
                    </NavLink>
                    <NavLink to={"/admin_client/perimetre/"} capability="admin_client.perimetre.all">
                        {intl.formatMessage("generic.sidebar.admin_client.perimetre")}
                    </NavLink>
                    <NavLink to={"/admin_client/contrepartie/"} capability="admin_client.contrepartie.all">
                        {intl.formatMessage("generic.sidebar.admin_client.contrepartie")}
                    </NavLink>
                    <NavLink to={"/admin_client/actif/"} capability="admin_client.actif.all">
                        {intl.formatMessage("generic.sidebar.admin_client.actif")}
                    </NavLink>
                    <NavLink to={"/admin_client/cle_analytique/"} capability="admin_client.cle_analytique.all">
                        {intl.formatMessage("generic.sidebar.admin_client.cle_analytique")}
                    </NavLink>
                    <NavLink to={"/admin_client/ratio/"} capability="admin_client.ratio.all">
                        {intl.formatMessage("generic.sidebar.admin_client.ratio")}
                    </NavLink>
                    <NavLink to={"/admin_client/indice_structure/"} capability="admin_client.indice_structure.all">
                        {intl.formatMessage("generic.sidebar.admin_client.indice_structure")}
                    </NavLink>
                    <NavLink to={"/admin_client/utilisateur/"} capability="admin_client.utilisateur.all">
                        {intl.formatMessage("generic.sidebar.admin_client.utilisateur")}
                    </NavLink>
                    <NavLink to={"/admin_client/flux/"} capability="admin_client.flux.all">
                        {intl.formatMessage("generic.sidebar.admin_client.flux")}
                    </NavLink>
                </SidebarDropdown>
                <SidebarDropdown
                    link="/compte/"
                    capability="account.*"
                    icon="user"
                    title={intl.formatMessage("generic.sidebar.account")}
                    toggleDropdown={toggleDropdown}
                    current={openedDropdown}
                >
                    <NavLink to={"/compte/information/"} capability="account.informations">
                        {intl.formatMessage("generic.sidebar.account.information")}
                    </NavLink>
                </SidebarDropdown>
            </ul>
            <div className="sidebar__copyright">
                <p className="margin-bottom--3">
                    <Link to="https://www.forexfinance.com/">
                        <img alt="" src={LogoForex} />
                    </Link>
                </p>
                <p>
                    <Link to={"/page/mentions-legales"}>{intl.formatMessage("generic.sidebar.legal")}</Link>
                    <br />
                    <Link to={"/page/conditions-d-utilisation"}>{intl.formatMessage("generic.sidebar.cgu")}</Link>
                    <br />
                    <br />
                    {intl.formatMessage("generic.sidebar.copyright")}
                    <br />
                    {intl.formatMessage("generic.sidebar.date", { year: new Date().getFullYear() })}
                </p>
            </div>
        </nav>
    );
};

export default Sidebar;
