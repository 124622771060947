import React from "react";
import { Backdrop, Loader as LoaderUI } from "ui/Loader";

const Loader: React.FC = () => {
    return (
        <Backdrop>
            <LoaderUI message="Loading" />
        </Backdrop>
    );
};

export default Loader;
