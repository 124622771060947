import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { IntlContext } from "context";
import NavLink from "../NavLink";

const TopbarSwitchAdmin: React.FC = () => {
    const intl = useContext(IntlContext);
    const match = useRouteMatch();
    const currentPageIsAdmin = match.path.startsWith("/admin/");

    return (
        <React.Fragment>
            {!currentPageIsAdmin && (
                <NavLink className="appbar__toolbar-link" to="/admin/customers/" capability="admin.*">
                    <Icon
                        icon={{ prefix: "fas", iconName: "lock" }}
                        size="2x"
                        title={intl.formatMessage("generic.menu.switch.to_admin")}
                    />
                </NavLink>
            )}
            {currentPageIsAdmin && (
                <NavLink className="appbar__toolbar-link" to="/analyse/" capability="analyse.*">
                    <Icon
                        icon={{ prefix: "fas", iconName: "chart-bar" }}
                        size="2x"
                        title={intl.formatMessage("generic.menu.switch.to_analyse")}
                    />
                </NavLink>
            )}
        </React.Fragment>
    );
};

export default TopbarSwitchAdmin;
