import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";
import { LigneMask } from "app/Financement/Saisie/context/Ligne/Context";

export interface LigneTypeInterface extends ReferenceInterface {
    sens: string;
    contrat_type_code: string;
    consolidation?: number;
    masque_saisie?: LigneMask;
}

class LigneType {
    all(languageCode: string) {
        return parseResponse<Array<LigneTypeInterface>>(fetch.get(`reference/ligne_type/${languageCode}`));
    }
}

export default new LigneType();
