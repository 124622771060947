import React, { useContext } from "react";
import { IntlContext } from "context";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import Label, { LabelProps } from "./Element/Label";
import Input, { InputProps } from "./Element/Input";
import Feedback from "./Element/Feedback";
import Button from "ui/Button";
import { TextField, SelectCustom, Textarea, Checkbox, File, Radio } from "components/Form/Field";
import AmountField from "components/Form/Field/Amount"; // else import error...

const customComponents: any = {
    TextField: TextField,
    SelectCustom: SelectCustom,
    TextareaField: Textarea,
    CheckboxField: Checkbox,
    RadioField: Radio,
    File: File,
    AmountField: AmountField,
};

const FieldRepeaterItem: React.FC<{
    name: string;
    index: number;
    field: any;
    errorMessage: any;
    disabled: boolean;
}> = ({ name, index, field, errorMessage, disabled }) => {
    const intl = useContext(IntlContext);
    const CustomComponent = customComponents[field.component];
    const fieldError = errorMessage?.props?.error;
    const hasError = fieldError !== undefined && fieldError?.[index]?.[field.key] !== undefined;
    const fieldName = field.key ? `${name}.${field.key}` : name;
    const errorCode = hasError && (field.key ? fieldError[index][field.key].code : fieldError[index].code);

    const fieldProps: any = Object.assign(
        { ...field },
        {
            name: fieldName,
            disabled: disabled,
            component: undefined,
        }
    );

    if (fieldProps.format !== undefined) {
        fieldProps.format = undefined;
    }

    return (
        <React.Fragment>
            {field.type === "hidden" && <Input {...fieldProps} />}
            {field.type !== "hidden" && (
                <div className="field__wrapper">
                    {field.format === undefined && (
                        <CustomComponent
                            inputProps={fieldProps}
                            labelProps={{
                                label: field.label,
                            }}
                        />
                    )}
                    {field.format !== undefined && (
                        <CustomComponent
                            inputProps={fieldProps}
                            labelProps={{
                                label: field.label,
                            }}
                            format={field.format}
                        />
                    )}
                    {hasError && (
                        <Feedback className="field__feedback error">
                            {intl.formatMessage(`error.${errorCode}`)}
                        </Feedback>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export interface FieldRepeaterProps {
    fields?: any;
    error?: boolean;
    errorMessage?: any;
    labelProps: LabelProps & {
        label: React.ReactNode;
        add?: string;
        delete?: string;
    };
    inputProps: InputProps & {
        innerFields: Array<any>;
        defaultInnerField: any;
        maxItems?: number;
    };
    fieldGroupProps?: FieldGroupProps;
}

const FieldRepeater: React.FC<FieldRepeaterProps> = ({
    fields,
    error,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
}) => {
    const intl = useContext(IntlContext);
    const disabled = inputProps.disabled || false;
    const required = inputProps.required;
    let title = "";

    if (labelProps !== undefined && labelProps.label !== undefined && labelProps.label !== null) {
        title = labelProps.label.toString();
    }

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <div className="field__repeater-container">
                <div
                    className={
                        "field__repeater-container--left" +
                        (error ? " error" : "") +
                        (!fields.length ? " is-empty" : "")
                    }
                    onClick={() => {
                        if (!fields.length) {
                            fields.push(inputProps.defaultInnerField);
                        }
                    }}
                    title={!fields.length && title !== "" ? title : ""}
                >
                    <div className="field__repeater-fields">
                        {fields &&
                            fields.map((name: string, index: number) => {
                                const hasError = errorMessage?.props?.error?.[index];
                                return (
                                    <div key={name} className={"field__repeater-field" + (hasError ? " error" : "")}>
                                        {inputProps.innerFields.map((innerField: any, index: number) => {
                                            return (
                                                <FieldRepeaterItem
                                                    key={innerField.key || index}
                                                    name={name}
                                                    index={index}
                                                    field={innerField}
                                                    errorMessage={errorMessage}
                                                    disabled={disabled}
                                                />
                                            );
                                        })}
                                        {labelProps !== undefined && !disabled && (
                                            <Button
                                                onClick={() => fields.remove(index)}
                                                title={
                                                    labelProps.delete ?? intl.formatMessage("generic.repeater.delete")
                                                }
                                                className="btn btn--icon btn--icon-small btn--red"
                                            >
                                                <Icon icon={{ prefix: "fas", iconName: "times" }} size="2x" />
                                            </Button>
                                        )}
                                        {disabled && (
                                            <Button className="btn btn--icon btn--icon-small btn--disabled">
                                                <Icon icon={{ prefix: "fas", iconName: "minus" }} />
                                            </Button>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                    {labelProps !== undefined && (
                        <Label
                            htmlFor={inputProps.id}
                            disabled={disabled}
                            error={error}
                            required={required}
                            {...labelProps}
                        >
                            {labelProps.label}
                        </Label>
                    )}
                </div>
                <div className="field__repeater-container--right">
                    {(inputProps.maxItems === undefined || fields.length < inputProps.maxItems) && (
                        <React.Fragment>
                            {labelProps !== undefined && !disabled && (
                                <Button
                                    onClick={() => fields.push(inputProps.defaultInnerField)}
                                    title={labelProps.add ?? intl.formatMessage("generic.repeater.add")}
                                    className="btn btn--icon btn--secondary"
                                >
                                    <Icon icon={{ prefix: "fas", iconName: "plus" }} />
                                </Button>
                            )}
                            {disabled && (
                                <Button className="btn btn--icon btn--disabled">
                                    <Icon icon={{ prefix: "fas", iconName: "plus" }} />
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>

            {errorMessage?.props?.error?.code && (
                <Feedback className="field__feedback error">
                    {intl.formatMessage(`error.${errorMessage?.props?.error?.code}`)}
                </Feedback>
            )}
        </FieldGroup>
    );
};

export default FieldRepeater;
