import React from "react";

export interface Options {
    value: string;
    label: React.ReactNode;
}

export interface SelectProps
    extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    options: Array<Options>;
    placeholder?: string;
    name: string;
}

const Select: React.FC<SelectProps> = ({ placeholder, options, className = "field-control__input", ...props }) => {
    return (
        <select className={className} {...props}>
            {placeholder && <option value={""}>{placeholder}</option>}
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Select;
