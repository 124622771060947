import React from "react";
import { Link } from "react-router-dom";
import SidebarIcon from "./Icon";
import ProtectedComponent from "components/Secured/ProtectedComponent";

const SidebarDropdown: React.FC<{
    link: string;
    capability: string;
    icon: string;
    iconPrefix?: string;
    title: string;
    toggleDropdown: (link: string) => void;
    current: string;
    to?: string;
}> = ({ link, capability, icon, iconPrefix, title, toggleDropdown, current, to, children }) => {
    const opened = link === current;

    const toggleItem = () => {
        toggleDropdown(link);
    };

    return (
        <ProtectedComponent capability={capability}>
            {to === undefined && (
                <React.Fragment>
                    <span className={"sidebar__nav_parent" + (opened ? " active" : "")} onClick={toggleItem}>
                        <SidebarIcon iconName={icon} iconPrefix={iconPrefix} />
                        {title}
                    </span>
                    {opened && <ul className="sidebar__nav_child">{children}</ul>}
                </React.Fragment>
            )}
            {to !== undefined && (
                <Link className={"sidebar__nav_parent" + (opened ? " active" : "")} to={to}>
                    <SidebarIcon iconName={icon} iconPrefix={iconPrefix} />
                    {title}
                </Link>
            )}
        </ProtectedComponent>
    );
};

export default SidebarDropdown;
