import React from "react";
import { FlashMessageStateInterface, FlashMessage } from "context/FlashMessages/Context";
import { Wrapper, Message } from "ui/FlashMessage";

interface Props {
    flash: FlashMessageStateInterface;
}
const animationDuration = 750;

const FlashMessages: React.FC<Props> = ({ flash }) => {
    const onFlashFadeOut = (flashMessage: FlashMessage) => {
        flash.actions.removeFlashMessage(flashMessage.id);
    };

    return (
        <Wrapper>
            {flash.messages.map((message) => (
                <Message
                    key={message.id}
                    onFadeOut={() => onFlashFadeOut(message)}
                    duration={message.duration}
                    animationDuration={animationDuration}
                    content={message.text}
                    type={message.type}
                />
            ))}
        </Wrapper>
    );
};

export default FlashMessages;
