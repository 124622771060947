import React, { useContext } from "react";
import { IntlContext } from "context";
import { InputProps } from "./Element/Input";
import FieldTableTd from "./Table/Td";
import Actions from "./Table/Actions";
import Button from "ui/Button";
import { getTranslateKeyFromErrorCode } from "components/Form/ErrorMessage";

export interface FieldTableProps {
    fields?: any;
    error?: boolean;
    errorMessage?: any;
    header?: () => any;
    footer?: () => any;
    saisieErrors?: any;
    inputProps: InputProps & {
        innerFields: Array<any>;
        defaultInnerField: any;
        showLineNumber?: boolean;
        showAddRowLink?: boolean;
        hideActions?: boolean;
        hideMove?: boolean;
        onRowMove?: (oldIndex: number, newIndex?: number) => void;
    };
}

const FieldTable: React.FC<FieldTableProps> = ({
    fields,
    error,
    errorMessage,
    header,
    footer,
    saisieErrors,
    inputProps,
}) => {
    const intl = useContext(IntlContext);

    const getFieldIndex = (fieldName: string): number | undefined => {
        const match = fieldName.match(/\[(\d+)\]/g);
        let index = undefined;

        if (match === null) {
            return index;
        }

        index = match.pop();

        if (index === undefined) {
            return index;
        }

        index = index.replace("[", "").replace("]", "");

        return parseInt(index);
    };

    const displayFormErrors = (errors: any): any => {
        let usedRepeaterErrors: Array<string> = [];

        return (
            <ul className="field__table__errors">
                {Object.keys(errors).map((index) => {
                    if (
                        index !== "code" &&
                        index !== "params" &&
                        errors[index] !== undefined &&
                        errors[index] !== null
                    ) {
                        return Object.keys(errors[index]).map((name) => {
                            const message = errors[index][name];
                            const code = message?.code ?? message?.props?.error?.code;
                            const params = message?.params ?? message?.props?.error?.params;

                            if (code !== undefined && params !== undefined) {
                                const displayMessage = intl.formatMessage(getTranslateKeyFromErrorCode(code), params);
                                return (
                                    <li key={index + "-" + name}>
                                        {intl.formatMessage("generic.field.table.row") +
                                            " " +
                                            (parseInt(index) + 1) +
                                            " : "}
                                        {displayMessage}
                                    </li>
                                );
                            }
                            return null;
                        });
                    } else if (!usedRepeaterErrors.includes(errors.code)) {
                        usedRepeaterErrors.push(errors.code);
                        const displayMessage = intl.formatMessage(
                            getTranslateKeyFromErrorCode(errors.code),
                            errors.params
                        );
                        return <li key={errors.code}>{displayMessage}</li>;
                    }
                    return null;
                })}
            </ul>
        );
    };

    return (
        <div
            className={
                "field__table" +
                (!inputProps.hideActions ? " has-actions" : "") +
                (inputProps.hideMove ? " no-move" : "")
            }
        >
            {errorMessage && errorMessage?.props?.error !== undefined && displayFormErrors(errorMessage.props.error)}
            {errorMessage && errorMessage?.props?.error === undefined && displayFormErrors(errorMessage)}
            {inputProps.showAddRowLink === true && (
                <p className="field__table__add-link">
                    <Button className="btn btn--link" onClick={() => fields.push(inputProps.defaultInnerField)}>
                        {intl.formatMessage("generic.field.table.row.add")}
                    </Button>
                </p>
            )}
            <table>
                {header !== undefined && <thead>{header()}</thead>}
                <tbody>
                    {fields &&
                        fields.map((name: string, index: number) => {
                            const isFirst = index === 0;
                            const isLast = index === fields.value.length - 1;
                            let actionsDisabled = false;

                            return (
                                <tr key={name}>
                                    {inputProps.showLineNumber && <td>{index + 1}</td>}
                                    {inputProps.innerFields.map((innerField: any) => {
                                        let field = Object.assign({}, innerField);

                                        if (field.isDisabled) {
                                            field.disabled = field.isDisabled(index);
                                            actionsDisabled = field.isDisabled(index);
                                            delete field.isDisabled;
                                        }

                                        return (
                                            <FieldTableTd
                                                key={innerField.key}
                                                name={name}
                                                index={index}
                                                field={field}
                                                errorMessage={errorMessage}
                                                saisieError={
                                                    saisieErrors !== undefined
                                                        ? saisieErrors[`${name}.${innerField.key}`]
                                                        : undefined
                                                }
                                            />
                                        );
                                    })}
                                    {!inputProps.hideActions && !actionsDisabled && (
                                        <td>
                                            <Actions
                                                fields={fields}
                                                index={index}
                                                isFirst={isFirst}
                                                isLast={isLast}
                                                defaultValues={inputProps.defaultInnerField}
                                                hideMove={inputProps.hideMove}
                                                onRowMove={inputProps.onRowMove}
                                            />
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                </tbody>
                {footer !== undefined && <tfoot>{footer()}</tfoot>}
            </table>
            {saisieErrors && (
                <ul className="field__table__errors">
                    {Object.keys(saisieErrors).map((fieldName) => {
                        if (saisieErrors[fieldName] !== undefined) {
                            const index = getFieldIndex(fieldName);

                            return (
                                <li key={fieldName}>
                                    {index !== undefined &&
                                        intl.formatMessage("generic.field.table.row") + " " + (index + 1) + " : "}
                                    {saisieErrors[fieldName]}
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            )}
        </div>
    );
};

export default FieldTable;
