import moment from "moment";

const dateMinimum = moment().subtract(100, "years").format("YYYY-MM-DD");
const dateMaximum = moment().add(100, "years").format("YYYY-MM-DD");

const dateFormatIsValid = (value: Date | string | number | undefined): boolean => {
    const test = moment(value, "YYYY-MM-DD");
    return !!(test.isValid() && test.isBetween(dateMinimum, dateMaximum));
};

export default dateFormatIsValid;
