import React from "react";
import { NavLink } from "react-router-dom";
import ProtectedComponent from "components/Secured/ProtectedComponent";

interface Props {
    to: string;
    className?: string;
    activeClassName?: string;
    activeStyle?: React.CSSProperties;
    exact?: boolean;
    strict?: boolean;
    capability?: string;
}

function isModifiedEvent(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const SidebarLink: React.FC<any> = ({ className, children, onClick, navigate, ...props }) => {
    const { target } = props;
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        try {
            if (onClick) onClick(event);
        } catch (ex) {
            event.preventDefault();
            throw ex;
        }

        if (
            !event.defaultPrevented && // onClick prevented default
            event.button === 0 && // ignore everything but left clicks
            (!target || target === "_self") && // let browser handle "target=_blank" etc.
            !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
            event.preventDefault();
            navigate();
        }
    };
    return (
        <li className={className}>
            <a className="sidebar__link" onClick={handleClick} {...props}>
                {children}
            </a>
        </li>
    );
};
const SidebarNavLink: React.FC<Props> = ({
    to,
    className = "sidebar__item",
    activeClassName = "sidebar__item active",
    strict,
    exact,
    children,
    capability,
}) => {
    return (
        <ProtectedComponent capability={capability}>
            <NavLink
                to={to}
                className={className}
                activeClassName={activeClassName}
                strict={strict}
                component={SidebarLink}
                exact={exact}
            >
                {children}
            </NavLink>
        </ProtectedComponent>
    );
};

export default SidebarNavLink;
