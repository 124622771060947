import React, { Suspense } from "react";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as ClientProvider } from "context/Client";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as ReferenceProvider } from "context/Reference";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import { Provider as AnalyseProvider } from "context/Analyse";
import { BrowserRouter } from "react-router-dom";

import "assets/scss/main.scss";
import "assets/icons";

import FlashMessages from "components/App/FlashMessage";
import ErrorBoundary from "components/App/ErrorBoundary";
import Loader from "components/App/Loader";
import ModuleRouter from "./ModuleRouter";

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <AuthProvider>
                <ClientProvider>
                    <IntlProvider>
                        <ReferenceProvider>
                            <AnalyseProvider>
                                <FlashMessagesProvider FlashComponent={FlashMessages}>
                                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                                        <Suspense fallback={<Loader />}>
                                            <ModuleRouter />
                                        </Suspense>
                                    </BrowserRouter>
                                </FlashMessagesProvider>
                            </AnalyseProvider>
                        </ReferenceProvider>
                    </IntlProvider>
                </ClientProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default App;
