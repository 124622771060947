import React, { useContext } from "react";
import { IntlContext } from "context";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import BooleanCheckbox, { BooleanCheckboxProps } from "./Element/BooleanCheckbox";
import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";

export interface FormBooleanCheckboxProps {
    error?: boolean;
    errorMessage?: any;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: BooleanCheckboxProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    fieldControlClassName?: string;
}

const FormBooleanCheckbox: React.FC<FormBooleanCheckboxProps> = ({
    error = false,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    fieldControlClassName,
}) => {
    const intl = useContext(IntlContext);
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <FieldControl
                className={`field-control-checkbox ${fieldControlClassName ? fieldControlClassName : ""}`}
                error={error}
                disabled={disabled}
                {...fieldControlProps}
            >
                <BooleanCheckbox disabled={disabled} required={required} {...inputProps} />
            </FieldControl>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            {error && errorMessage?.props?.error?.code && (
                <Feedback className="field__feedback error">
                    {errorMessage?.props?.error?.code &&
                        intl.formatMessage(`error.${errorMessage?.props?.error?.code}`)}
                    {!errorMessage?.props?.error?.code && errorMessage !== undefined && errorMessage}
                </Feedback>
            )}
        </FieldGroup>
    );
};

export default FormBooleanCheckbox;
