import React from "react";
import { useForm } from "react-final-form";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export interface Options {
    value: string;
    label: React.ReactNode;
}

export interface BooleanCheckboxProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    requiredSymbole?: string;
    groupClassName?: string;
    labelClassName?: string;
    name: string;
    value?: any;
    label: React.ReactNode;
    tooltip?: string;
}

const BooleanCheckbox: React.FC<BooleanCheckboxProps> = ({
    groupClassName,
    labelClassName,
    label,
    required,
    requiredSymbole = "\u00a0*",
    tooltip,
    ...props
}) => {
    const form = useForm();

    const toggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        form.change(props.name, event.target.checked);
        props.onChange && props.onChange(event);
    };

    return (
        <div className={groupClassName}>
            <input type="checkbox" {...props} onChange={(event) => toggle(event)} id={props.name} />
            <label className={labelClassName} htmlFor={props.name}>
                {label}
                {required && <span className={labelClassName + "--required"}>{requiredSymbole}</span>}
            </label>
            {props.checked && <Icon icon={{ prefix: "fab", iconName: "check" }} />}
        </div>
    );
};

BooleanCheckbox.defaultProps = {
    className: "field-control-checkbox__input",
    groupClassName: "field-control-checkbox__group",
    labelClassName: "field-control-checkbox__label",
};

export default BooleanCheckbox;
