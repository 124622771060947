import React from "react";
import FormFile, { FormFileProps } from "ui/Form/File";

import { Field, UseFieldConfig } from "react-final-form";

type Props = FormFileProps & UseFieldConfig<string>;

const FileField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    inputProps,
    infoMessage,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <FormFile
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        inputProps={Object.assign({}, inputProps, input)}
                        infoMessage={infoMessage}
                    />
                );
            }}
        </Field>
    );
};

export default FileField;
