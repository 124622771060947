import React from "react";
import { ErrorInfo } from "react";
import { withRouter, RouteChildrenProps } from "react-router-dom";
import { UnregisterCallback } from "history";
import { withIntl, IntlProps } from "context/Intl";

interface StateInterface {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<IntlProps & RouteChildrenProps> {
    state = {
        hasError: false,
    } as StateInterface;
    unlisten?: UnregisterCallback;

    componentDidMount() {
        const { history } = this.props;
        this.unlisten = history.listen((location, action) => {
            this.setState({
                hasError: false,
            });
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        const { intl } = this.props;
        if (this.state.hasError) {
            return (
                <div className="container margin-top--3">
                    <h1 className="page-title">{intl.formatMessage("generic.error.title")}</h1>
                    <p className="textspace--pre">{intl.formatMessage("generic.error.message")}</p>
                    {this.state.error?.message}
                </div>
            );
        }
        return this.props.children;
    }
}

export default withRouter(withIntl(ErrorBoundary));
