import React from "react";
import { ErrorInfo } from "react";
import { withIntl, IntlProps } from "context/Intl";

interface StateInterface {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<IntlProps> {
    state = {
        hasError: false,
    } as StateInterface;

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        const { intl } = this.props;
        if (this.state.hasError) {
            return (
                <div className="container">
                    <h1 className="page-title">{intl.formatMessage("generic.error.title")}</h1>
                    <p className="textspace--pre">{intl.formatMessage("generic.error.message")}</p>
                    {this.state.error?.message}
                </div>
            );
        }
        return this.props.children;
    }
}

export default withIntl(ErrorBoundary);
