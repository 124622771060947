import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faCheckSquare,
    faCoffee,
    faPowerOff,
    faEdit,
    faTrashAlt,
    faLock,
    faUnlock,
    faSort,
    faSortDown,
    faSortUp,
    faMinus,
    faPlus,
    faChartLine,
    faBorderAll,
    faEquals,
    faCaretUp,
    faCaretDown,
    faSignal,
    faListUl,
    faCog,
    faUser,
    faChartBar,
    faList,
    faHotel,
    faUsers,
    faPencilAlt,
    faPercentage,
    faArrowUp,
    faArrowDown,
    faTimes,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faCogs,
    faToggleOn,
    faToggleOff,
} from "@fortawesome/free-solid-svg-icons";

import { faCircle } from "@fortawesome/free-regular-svg-icons";

const forexAdmin: IconDefinition = {
    prefix: "fab",
    iconName: "cog",
    icon: [
        10,
        10,
        [],
        "f000",
        [
            "M3.4,10a.96.96,0,0,1-.355-.064A1.055,1.055,0,0,1,2.4,9a1.437,1.437,0,0,0-.329-.98,1.46,1.46,0,0,0-.9-.3q-.045,0-.089,0l-.049,0a1.068,1.068,0,0,1-.907-.613A1.049,1.049,0,0,1,.341,5.991,1.44,1.44,0,0,0,.8,5.065a1.5,1.5,0,0,0-.491-.912A1.051,1.051,0,0,1,.065,3.047a1.057,1.057,0,0,1,.912-.641h.064a1.435,1.435,0,0,0,.94-.331,1.459,1.459,0,0,0,.3-.99A1.052,1.052,0,0,1,2.892.129.97.97,0,0,1,3.275.054a1.018,1.018,0,0,1,.731.287A1.452,1.452,0,0,0,4.915.8h.016A1.505,1.505,0,0,0,5.844.309,1.016,1.016,0,0,1,6.6,0a.952.952,0,0,1,.355.065A1.053,1.053,0,0,1,7.591,1a1.442,1.442,0,0,0,.33.982,1.456,1.456,0,0,0,.9.3q.045,0,.089,0l.049,0a1.067,1.067,0,0,1,.907.611A1.076,1.076,0,0,1,9.66,4.011a1.427,1.427,0,0,0-.462.926,1.479,1.479,0,0,0,.493.913A1.016,1.016,0,0,1,9.027,7.6H8.946a1.422,1.422,0,0,0-.929.328,1.459,1.459,0,0,0-.3.99,1.05,1.05,0,0,1-.611.955.98.98,0,0,1-.387.076,1.017,1.017,0,0,1-.727-.285A1.475,1.475,0,0,0,5.073,9.2H5.064a1.473,1.473,0,0,0-.912.488A1.016,1.016,0,0,1,3.4,10ZM4.995,3.054A1.946,1.946,0,1,0,6.782,4.23,1.932,1.932,0,0,0,4.995,3.054Z",
        ],
    ],
};

const forexAnalyse: IconDefinition = {
    prefix: "fab",
    iconName: "signal",
    icon: [
        10,
        10,
        [],
        "f000",
        [
            "M9.634,10H7.8a.364.364,0,0,1-.366-.36V.36A.364.364,0,0,1,7.8,0H9.634A.364.364,0,0,1,10,.36V9.64A.364.364,0,0,1,9.634,10ZM5.916,10H4.084a.364.364,0,0,1-.367-.36V3.154a.365.365,0,0,1,.367-.36H5.916a.364.364,0,0,1,.367.36V9.64A.364.364,0,0,1,5.916,10ZM2.2,10H.366A.364.364,0,0,1,0,9.64v-3.8a.364.364,0,0,1,.366-.36H2.2a.364.364,0,0,1,.366.36v3.8A.364.364,0,0,1,2.2,10Z",
        ],
    ],
};

const forexFinancement: IconDefinition = {
    prefix: "fab",
    iconName: "list",
    icon: [
        10,
        10,
        [],
        "f000",
        [
            "M4.248,10c-.275,0-.5-.165-.5-.366V7.8c0-.2.223-.366.5-.366H9.5c.275,0,.5.165.5.366V9.634c0,.2-.224.366-.5.366ZM0,8.75A1.25,1.25,0,1,1,1.25,10,1.25,1.25,0,0,1,0,8.75ZM4.063,6.282a.343.343,0,0,1-.312-.366V4.084a.344.344,0,0,1,.312-.367H9.688A.344.344,0,0,1,10,4.084V5.917a.343.343,0,0,1-.312.365ZM0,5A1.25,1.25,0,1,1,1.25,6.25,1.25,1.25,0,0,1,0,5ZM3.975,2.566c-.124,0-.225-.166-.225-.367V.366c0-.2.1-.366.225-.366h5.8C9.9,0,10,.165,10,.366V2.2c0,.2-.1.367-.225.367ZM0,1.25A1.25,1.25,0,1,1,1.25,2.5,1.25,1.25,0,0,1,0,1.25Z",
        ],
    ],
};

const forexUser: IconDefinition = {
    prefix: "fab",
    iconName: "user",
    icon: [
        10,
        10,
        [],
        "f000",
        [
            "M4.438,10C2.3,10,0,9.654,0,8.894,0,6.931,1.991,5.334,4.438,5.334s4.437,1.6,4.437,3.559C8.875,9.654,6.575,10,4.438,10Zm0-5.212A2.394,2.394,0,1,1,6.832,2.394,2.4,2.4,0,0,1,4.438,4.788Z",
        ],
    ],
};

const forexCaretDown: IconDefinition = {
    prefix: "fab",
    iconName: "caret-down",
    icon: [
        9,
        6,
        [],
        "f000",
        ["M8.95.283A.5.5,0,0,0,8.5,0H.5a.5.5,0,0,0-.39.812l4,5a.5.5,0,0,0,.781,0l4-5A.5.5,0,0,0,8.95.283"],
    ],
};

const forexDownload: IconDefinition = {
    prefix: "fab",
    iconName: "download",
    icon: [
        7,
        10,
        [],
        "f000",
        [
            "M6.834,10H.184A.175.175,0,0,1,.01,9.833V8.722a.175.175,0,0,1,.174-.167H6.822A.175.175,0,0,1,7,8.722V9.833A.157.157,0,0,1,6.834,10ZM3.507,7.744a.243.243,0,0,1-.166-.066L.056,4.211A.193.193,0,0,1,.016,4a.218.218,0,0,1,.2-.13H1.826V.2A.2.2,0,0,1,2.034,0H4.949a.2.2,0,0,1,.208.2V3.866H6.764A.229.229,0,0,1,6.981,4a.2.2,0,0,1-.032.216L3.665,7.678A.213.213,0,0,1,3.507,7.744Z",
        ],
    ],
};

const forexTimes: IconDefinition = {
    prefix: "fab",
    iconName: "times",
    icon: [
        24.4,
        24.4,
        [],
        "f000",
        ["M13.4,12.4L24,23l-1.4,1.4L12,13.8L1.8,24l-1.4-1.4l10.3-10.3L0,1.8l1.4-1.4L12,11L23,0l1.4,1.4L13.4,12.4z"],
    ],
};

const forexTick: IconDefinition = {
    prefix: "fab",
    iconName: "check",
    icon: [
        6.513,
        5.5,
        [],
        "f000",
        [
            "M5.5,0a.461.461,0,0,0-.33.16C4,1.451,3.109,2.521,2,3.753L.79,2.628a.447.447,0,0,0-.472-.095.515.515,0,0,0-.311.4.555.555,0,0,0,.161.5L1.727,4.874a.449.449,0,0,0,.652-.029C3.666,3.43,4.585,2.3,5.854.9A.563.563,0,0,0,5.961.319.479.479,0,0,0,5.5,0Z",
        ],
    ],
};

library.add(
    faCheckSquare,
    faCoffee,
    faPowerOff,
    faEdit,
    faTrashAlt,
    faLock,
    faUnlock,
    faSort,
    faSortDown,
    faSortUp,
    faCircle,
    faMinus,
    faPlus,
    faChartLine,
    faBorderAll,
    faEquals,
    faCaretUp,
    faCaretDown,
    faSignal,
    faListUl,
    faCog,
    faUser,
    faChartBar,
    faList,
    faHotel,
    faUsers,
    faPencilAlt,
    faPercentage,
    faArrowUp,
    faArrowDown,
    faTimes,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faCogs,
    faToggleOn,
    faToggleOff,

    forexAdmin,
    forexAnalyse,
    forexFinancement,
    forexUser,
    forexCaretDown,
    forexDownload,
    forexTimes,
    forexTick
);
