import React from "react";
import FieldText, { FieldTextProps } from "ui/Form/Text";
import { Field, UseFieldConfig } from "react-final-form";

type Props = FieldTextProps & UseFieldConfig<string>;

export const cleanAmountValue = (value: any): any => {
    let val = value
        .replace(/ /g, "")
        .replace(/,/g, ".") // replace comma by dot
        .replace(/(?!^)-/g, "") // remove middle hyphen
        .replace(/^0+(\d)/gm, "$1") // remove multiple leading zeros
        .replace(/(\..*)\./g, "$1") // remove multiple dots
        .replace(/[^0-9.-]/g, ""); // remove all chars except number, dot, hyphen

    return val;
};

export const cleanInitialValue = (value: string): string => {
    let val = cleanAmountValue(value)
        .replace(/(\.\d*?[1-9])0+$/, "$1") // remove trailing zeros
        .replace(/\.$/, "$1"); // remove trailing dot
    return val;
};

const TauxField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    infoMessage,
    ...fieldProps
}) => {
    const formatValue = (value: any): any => {
        if (value === undefined || value === null || value === "") {
            return "";
        } else if (value === "-") {
            return "-";
        }

        return value.toString();
    };

    //  /!\ You may have to use cleanAmountValue function
    //      if you use form.change(e.target.value, cleanAmountValue(e.target.value))

    return (
        <Field
            name={inputProps.name}
            parse={(value: any) => cleanAmountValue(value)} // submitted value
            format={(value: any) => formatValue(value)} // visible value
            {...fieldProps}
        >
            {({ input, meta }) => {
                return (
                    <FieldText
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        adornmentStart={adornmentStart}
                        adornmentEnd={adornmentEnd}
                        inputProps={Object.assign({}, input, inputProps)}
                        infoMessage={infoMessage}
                    />
                );
            }}
        </Field>
    );
};

export default TauxField;
