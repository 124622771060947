import React from "react";

const LoaderBackdrop: React.FC = ({ children }) => {
    return (
        <div className="loader-backdrop-wrapper loader-backdrop-wrapper--full">
            <div className="loader-backdrop" />
            {children}
        </div>
    );
};

export default LoaderBackdrop;
