import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import NavLink from "../NavLink";
import AppbarDropdown from "../AppbarDropdown";

const MenuUser: React.FC = () => {
    const intl = useContext(IntlContext);
    const auth = useContext(AuthContext);
    const profile = auth.account?.user;

    return (
        <AppbarDropdown buttonContent={`${profile?.first_name} ${profile?.last_name}`}>
            <NavLink className="appbar-dropdown__item" to="/compte/information/" capability="account.informations">
                {intl.formatMessage("generic.menu.account.information")}
            </NavLink>

            <div className="appbar-dropdown__divider"></div>

            <button type="button" onClick={() => auth.logout()} className="appbar-dropdown__item">
                {intl.formatMessage("generic.menu.logout")}
            </button>
        </AppbarDropdown>
    );
};

export default MenuUser;
