import React, { useContext } from "react";
import { AuthContext } from "context";
import { Switch, Route, Redirect } from "react-router";
import ProtectedRoute from "components/Secured/ProtectedRoute";
import { Minimal as MinimalLayout } from "components/Layout/";
import LogoutOnExpiration from "components/App/LogoutOnExpiration";

const Authentication = React.lazy(() => import("app/Authentication"));
const Admin = React.lazy(() => import("app/Admin"));
const Account = React.lazy(() => import("app/Account"));
const StyleGuide = React.lazy(() => import("app/StyleGuide"));
const Analyse = React.lazy(() => import("app/Analyse"));
const Financement = React.lazy(() => import("app/Financement"));
const DonneesMarches = React.lazy(() => import("app/DonneesMarches"));
const AdminClient = React.lazy(() => import("app/AdminClient"));
const Fiche = React.lazy(() => import("app/Fiche"));
const Page = React.lazy(() => import("app/Pages"));

const AuthModule: React.FC = () => {
    const auth = useContext(AuthContext);

    return (
        <LogoutOnExpiration>
            <Switch>
                <ProtectedRoute strict capability="admin.*" path="/admin/" render={() => <Admin />} />
                <ProtectedRoute strict capability="account.*" path="/compte/" render={() => <Account />} />
                <ProtectedRoute strict capability="admin.*" path="/styleguide/" render={() => <StyleGuide />} />
                <ProtectedRoute strict capability="analyse.*" path="/analyse/" render={() => <Analyse />} />
                <ProtectedRoute strict capability="financement.*" path="/financement/" render={() => <Financement />} />
                <ProtectedRoute
                    strict
                    capability="financement.*"
                    path="/donnees_marches/"
                    render={() => <DonneesMarches />}
                />
                <ProtectedRoute strict capability="fiche.*" path="/fiche/" render={() => <Fiche />} />
                <ProtectedRoute strict capability="page.*" path="/page/" render={() => <Page />} />
                <ProtectedRoute
                    strict
                    capability="admin_client.*"
                    path="/admin_client/"
                    render={() => <AdminClient />}
                />
                <ProtectedRoute
                    capability="*"
                    render={() => {
                        if (auth.isAllowed("admin.*")) {
                            return <Redirect to="/admin/" />;
                        }
                        return <Redirect to="/analyse/dashboard/" />;
                    }}
                />
            </Switch>
        </LogoutOnExpiration>
    );
};

const NoAuthModule: React.FC = () => {
    return (
        <MinimalLayout>
            <Switch>
                <Route render={() => <Authentication />} />
            </Switch>
        </MinimalLayout>
    );
};

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    if (auth.isLoggedIn) {
        return <AuthModule />;
    }
    return <NoAuthModule />;
};

export default ModuleRouter;
