import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface RatioInterface extends ReferenceInterface {
    societe_id: string;
}

class Ratio {
    all(clientId: string) {
        return parseResponse<Array<RatioInterface>>(fetch.get(`reference/ratio/${clientId}`));
    }
}

export default new Ratio();
