import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface ActifInterface extends ReferenceInterface {}

class Actif {
    all(clientId: string) {
        return parseResponse<Array<ActifInterface>>(fetch.get(`reference/actif/${clientId}`));
    }
}

export default new Actif();
