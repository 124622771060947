import { useReducer, useEffect, useState } from "react";
import Axios from "axios";

interface IUsePromiseState<T> {
    data: T;
    isLoading: boolean;
    hasError: boolean;
    error: any;
    refresh: boolean;
}

interface IUsePromiseResult<T> {
    data: T;
    isLoading: boolean;
    hasError: boolean;
    error: any;
    refreshData: () => void;
    promiseParams?: any;
    changePromiseParams: (params?: any) => void;
}

enum ReducerActions {
    FETCH_INIT = "FETCH_INIT",
    FETCH_SUCCESS = "FETCH_SUCCESS",
    FETCH_FAILURE = "FETCH_FAILURE",
    REFRESH_DATA = "REFRESH_DATA",
}

function dataFetchReducer<T>(
    state: IUsePromiseState<T>,
    action: { type: ReducerActions; payload?: any }
): IUsePromiseState<T> {
    switch (action.type) {
        case ReducerActions.FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                error: null,
            };
        case ReducerActions.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                data: action.payload,
                error: null,
            };
        case ReducerActions.FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.payload,
            };
        case ReducerActions.REFRESH_DATA:
            return {
                ...state,
                refresh: !state.refresh,
            };
        default:
            throw new Error();
    }
}

export default function usePromise<T>(
    promise: (params?: any) => Promise<T>,
    defaultPromiseParams?: any,
    initialData?: T
): IUsePromiseResult<T> {
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        error: null,
        data: initialData,
        refresh: false,
    });

    const [promiseParams, changePromiseParams] = useState(defaultPromiseParams);

    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            dispatch({ type: ReducerActions.FETCH_INIT });
            try {
                let result = await promise(promiseParams);
                if (!didCancel) {
                    dispatch({ type: ReducerActions.FETCH_SUCCESS, payload: result });
                }
            } catch (error: any) {
                if (!didCancel && !Axios.isCancel(error)) {
                    dispatch({ type: ReducerActions.FETCH_FAILURE, payload: error });
                }
            }
        };
        fetchData();
        return () => {
            didCancel = true;
        };
    }, [promise, state.refresh, promiseParams]);

    return {
        isLoading: state.isLoading,
        hasError: state.hasError,
        error: state.error,
        data: state.data,
        refreshData: () => dispatch({ type: ReducerActions.REFRESH_DATA }),
        promiseParams,
        changePromiseParams,
    } as IUsePromiseResult<T>;
}
