import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CountryInterface extends ReferenceInterface {
    hors_EUA: boolean;
}

class Country {
    all(languageCode: string) {
        return parseResponse<Array<CountryInterface>>(fetch.get(`reference/country/${languageCode}`));
    }
}

export default new Country();
