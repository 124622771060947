import fetch, { parseResponse } from "./fetch";

export interface AuthData {
    user: {
        id: string;
        gender: "male" | "female";
        first_name: string;
        last_name: string;
        email: string;
        username: string;
        language_code: string;
    };
    capabilities: Array<string>;
    resources: Array<any>;
    clients: Array<any>;
    params: {
        default_client: string;
        default_perimetre: string;
    };
    error?: any;
}

export interface ResetPasswordInterface {
    username: string;
    new_password: string;
    confirm_password: string;
    token: string;
}

class Authentication {
    login(username: string, password: string) {
        return parseResponse<AuthData>(
            fetch.post(`authentication/login/`, {
                username,
                password,
            })
        );
    }

    logout() {
        return parseResponse(fetch.post(`authentication/logout/`));
    }

    fresh() {
        return parseResponse<AuthData>(fetch.get(`authentication/fresh/`));
    }

    forgotPassword(username: string) {
        return parseResponse(
            fetch.post(`authentication/forgot_password/`, {
                username,
            })
        );
    }

    resetPassword(values: ResetPasswordInterface) {
        return parseResponse(fetch.post(`authentication/reset_password/`, values));
    }

    checkResetToken(token: string) {
        return parseResponse<any>(
            fetch.post(`authentication/check_reset_token/`, {
                token,
            })
        );
    }
}

export default new Authentication();
