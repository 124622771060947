import React, { useContext } from "react";
import { IntlContext } from "context";
import ProtectedComponent from "components/Secured/ProtectedComponent";
import NavLink from "../NavLink";
import AppbarDropdown from "../AppbarDropdown";

const MenuAdmin: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <ProtectedComponent capability="admin.*">
            <AppbarDropdown buttonContent="Administration FOREX">
                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/customers"
                    capability="admin.customers"
                >
                    {intl.formatMessage("generic.menu.admin.clients")}
                </NavLink>

                <div className="appbar-dropdown__divider"></div>

                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/banks"
                    capability="admin.banks"
                >
                    {intl.formatMessage("generic.menu.admin.banques")}
                </NavLink>

                <div className="appbar-dropdown__divider"></div>

                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/users"
                    capability="admin.users"
                >
                    {intl.formatMessage("generic.menu.admin.users")}
                </NavLink>

                <div className="appbar-dropdown__divider"></div>

                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/references"
                    capability="admin.references"
                >
                    {intl.formatMessage("generic.menu.admin.references")}
                </NavLink>

                <div className="appbar-dropdown__divider"></div>

                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/settings"
                    capability="admin.settings"
                >
                    {intl.formatMessage("generic.menu.admin.parametres")}
                </NavLink>

                <NavLink
                    className="appbar-dropdown__item"
                    activeClassName="appbar-dropdown__item appbar-dropdown__item--active"
                    to="/admin/pages"
                    capability="admin.page"
                >
                    {intl.formatMessage("generic.menu.admin.page")}
                </NavLink>
            </AppbarDropdown>
        </ProtectedComponent>
    );
};

export default MenuAdmin;
