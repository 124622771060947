class Node {
    name: string;

    private allowed: boolean;

    private children: HashMap<Node>;

    constructor(name: string, allowed = false) {
        this.name = name;
        this.allowed = allowed;
        this.children = {};
    }

    addChild(node: Node) {
        this.children[node.name] = node;
    }

    allow(aclTree: Array<string>) {
        const tree = aclTree.slice();

        if (tree.length > 0) {
            const child = tree.shift();
            if (!child) {
                return;
            }
            if (!this.children[child]) {
                this.addChild(new Node(child));
            }
            this.children[child].allow(tree);
        } else {
            this.allowed = true;
        }
    }

    isAllowed(aclTree: Array<string>): boolean {
        const tree = aclTree.slice();

        if (tree.length > 0) {
            const child = tree.shift();

            if (child === "*") {
                for (const name in this.children) {
                    return this.children[name].isAllowed(tree);
                }
            } else {
                if (child && this.children[child]) {
                    return this.children[child].isAllowed(tree);
                } else if (Object.keys(this.children).length === 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return this.allowed;
    }
}

export default Node;
