import React, { useContext } from "react";
import { AuthContext } from "context";

interface Props {
    capability?: string;
}

const ProtectedComponent: React.FC<Props> = ({ capability, children }) => {
    const { isAllowed } = useContext(AuthContext);

    if (capability ? isAllowed(capability) : true) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
};

export default ProtectedComponent;
