import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import { FieldControlProps } from "./Element/FieldControl";
import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";
import Input, { SelectInputProps } from "./Select/Input";
import Downshift, { GetMenuPropsOptions, GetItemPropsOptions, DownshiftState, StateChangeOptions } from "downshift";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export interface Options {
    value: string | number;
    label: string;
}

export interface SelectProps {
    id?: string;
    autofocus?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    className?: string;
    placeholder?: string;
    onChange?: (selectedItem: string | number, index?: number) => void;
    value?: string | number;
    name: string;
    options: Array<Options>;
    searchInputProps?: SelectInputProps;
    menuProps?: GetMenuPropsOptions;
    itemProps?: GetItemPropsOptions<any>;
    itemToString?: (item: Options | null) => string;
    emptyItemValue?: Options;
    size?: number | string;
    title?: string;
}

export interface FormSelectCustomProps {
    error?: boolean;
    errorMessage?: string;
    infoMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: SelectProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const stateReducer = (state: DownshiftState<Options>, changes: StateChangeOptions<Options>) => {
    switch (changes.type) {
        case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
        case Downshift.stateChangeTypes.blurInput:
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
            return {
                ...changes,
                inputValue: "",
            };
        default:
            return changes;
    }
};

const defaultItemToString = (item: Options | null) => (item ? item.label : "");

const FormSelectCustom: React.FC<FormSelectCustomProps> = ({
    error,
    errorMessage,
    infoMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps: fieldControlPropsExtended,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    const fieldControlProps = Object.assign(
        {
            className: "field-control",
            errorClassName: "error",
            disabledClassName: "disabled",
        },
        fieldControlPropsExtended
    );

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    const selectedItem = inputProps.options.find((item) => item.value === inputProps.value);
    const index = parseInt(inputProps.name.match(/[(\d*)]/)?.join("") ?? "") ?? undefined; // for repeater fields
    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <Downshift
                selectedItem={selectedItem || null}
                onChange={(selectedItem) => {
                    if (selectedItem) {
                        inputProps.onChange && inputProps.onChange(selectedItem.value, index);
                    } else {
                        inputProps.onChange && inputProps.onChange("");
                    }
                }}
                defaultHighlightedIndex={1}
                itemToString={inputProps.itemToString || defaultItemToString}
                stateReducer={stateReducer}
                initialInputValue=""
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                    toggleMenu,
                    itemToString,
                }) => {
                    let sizeClassName = "";
                    if (inputProps.size) {
                        sizeClassName = ` size--${inputProps.size}`;
                    }
                    return (
                        <div className="field-control-wrapper">
                            <div
                                className={`${fieldControlProps.className} ${
                                    disabled ? fieldControlProps.disabledClassName : ""
                                } ${error ? fieldControlProps.errorClassName : ""}${sizeClassName}`}
                            >
                                {adornmentStart !== undefined && (
                                    <Adornment position="start">{adornmentStart}</Adornment>
                                )}
                                <div
                                    className={
                                        inputProps.className || "field-control__select" + (isOpen ? " is-open" : "")
                                    }
                                >
                                    {selectedItem && inputValue === "" && (
                                        <div
                                            className={`field-control-select__selected_item ${
                                                disabled ? "disabled" : ""
                                            }`}
                                        >
                                            {itemToString(selectedItem)}
                                        </div>
                                    )}
                                    <Input
                                        {...getInputProps({
                                            id: inputProps.id,
                                            name: inputProps.name,
                                            placeholder: selectedItem ? undefined : inputProps.placeholder || " ",
                                            autoFocus: inputProps.autofocus,
                                            disabled: inputProps.disabled,
                                            onFocus: openMenu,
                                            onMouseDown: isOpen ? toggleMenu : openMenu, // mouseDown fired before focus
                                            title: inputProps.title ?? "",
                                            ...inputProps.searchInputProps,
                                        })}
                                    />
                                    {labelProps !== undefined && (
                                        <Label
                                            htmlFor={inputProps.id}
                                            disabled={disabled}
                                            error={error}
                                            required={required}
                                            {...labelProps}
                                        >
                                            {labelProps.label}
                                        </Label>
                                    )}
                                    <Icon
                                        icon={{ prefix: "fas", iconName: "caret-down" }}
                                        className={isOpen ? "up" : ""}
                                    />
                                </div>
                                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
                                {isOpen && (
                                    <ul
                                        {...getMenuProps({
                                            className: "field-control-select__list",
                                            ...inputProps.menuProps,
                                        })}
                                    >
                                        {!required && (
                                            <li
                                                {...getItemProps({
                                                    index: 0,
                                                    item: inputProps.emptyItemValue || { value: "", label: "\u00a0" },
                                                    className: `field-control-select__item ${
                                                        highlightedIndex === 0 ? "highlighted" : ""
                                                    }`,
                                                    ...inputProps.itemProps,
                                                })}
                                            >
                                                {itemToString(
                                                    inputProps.emptyItemValue || { value: "", label: "\u00a0" }
                                                )}
                                            </li>
                                        )}
                                        {inputProps.options
                                            .filter(
                                                (item) =>
                                                    !inputValue ||
                                                    itemToString(item)
                                                        .toLocaleLowerCase()
                                                        .includes(inputValue.toLocaleLowerCase())
                                            )
                                            .map((item, index) => (
                                                <li
                                                    {...getItemProps({
                                                        key: item.value,
                                                        index: index + 1,
                                                        item,
                                                        className: `field-control-select__item ${
                                                            highlightedIndex === index + 1 ? "highlighted" : ""
                                                        } ${selectedItem === item ? "selected" : ""}`,
                                                        ...inputProps.itemProps,
                                                    })}
                                                >
                                                    {itemToString(item)}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
            {infoMessage !== undefined && !disabled && <Feedback className="field__feedback">{infoMessage}</Feedback>}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormSelectCustom;
