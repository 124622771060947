import React from "react";

export interface FieldControlProps {
    className?: string;
    errorClassName?: string;
    error?: boolean;
    disabledClassName?: string;
    disabled?: boolean;
}

const FieldControl: React.FC<FieldControlProps> = ({
    children,
    className = "field-control",
    errorClassName = "error",
    error,
    disabledClassName = "disabled",
    disabled,
}) => {
    return (
        <div className={`${className} ${disabled ? disabledClassName : ""} ${error ? errorClassName : ""}`}>
            {children}
        </div>
    );
};

export default FieldControl;
