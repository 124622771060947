import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Textarea, { TextareaProps } from "./Element/Textarea";

import Label, { LabelProps } from "./Element/Label";
import Adornment from "./Element/Adornment";
import Feedback from "./Element/Feedback";

export interface FormTextareaProps {
    error?: boolean;
    errorMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: TextareaProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
    adornmentStart?: React.ReactNode;
    adornmentEnd?: React.ReactNode;
}

const FormTextarea: React.FC<FormTextareaProps> = ({
    error,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;

    if (
        inputProps.title === undefined &&
        labelProps !== undefined &&
        labelProps.label !== undefined &&
        labelProps.label !== null
    ) {
        inputProps.title = labelProps.label.toString();
    }

    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <FieldControl disabled={disabled} error={error} {...fieldControlProps}>
                {adornmentStart !== undefined && <Adornment position="start">{adornmentStart}</Adornment>}

                <Textarea disabled={disabled} {...inputProps} />

                {labelProps !== undefined && (
                    <Label
                        htmlFor={inputProps.id}
                        disabled={disabled}
                        error={error}
                        required={required}
                        {...labelProps}
                    >
                        {labelProps.label}
                    </Label>
                )}

                {adornmentEnd !== undefined && <Adornment position="end">{adornmentEnd}</Adornment>}
            </FieldControl>

            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

export default FormTextarea;
