import React from "react";
import FieldText, { FieldTextProps } from "ui/Form/Text";
import FieldDate from "ui/Form/Date";

import { Field, UseFieldConfig } from "react-final-form";

type Props = FieldTextProps & UseFieldConfig<string>;

const TextField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    adornmentStart,
    adornmentEnd,
    inputProps,
    infoMessage,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} {...fieldProps}>
            {({ input, meta }) => {
                if (inputProps.type === "date") {
                    return (
                        <FieldDate
                            error={!!(meta.error || meta.submitError)}
                            errorMessage={meta.error || meta.submitError}
                            labelProps={labelProps}
                            fieldGroupProps={fieldGroupProps}
                            fieldControlProps={fieldControlProps}
                            adornmentStart={adornmentStart}
                            adornmentEnd={adornmentEnd}
                            inputProps={Object.assign({}, input, inputProps)}
                            infoMessage={infoMessage}
                        />
                    );
                } else {
                    return (
                        <FieldText
                            error={!!(meta.error || meta.submitError)}
                            errorMessage={meta.error || meta.submitError}
                            labelProps={labelProps}
                            fieldGroupProps={fieldGroupProps}
                            fieldControlProps={fieldControlProps}
                            adornmentStart={adornmentStart}
                            adornmentEnd={adornmentEnd}
                            inputProps={Object.assign({}, input, inputProps)}
                            infoMessage={infoMessage}
                        />
                    );
                }
            }}
        </Field>
    );
};

export default TextField;
