import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface FluxInterface extends ReferenceInterface {
    code_client?: string;
}

class Flux {
    all(clientId: string) {
        return parseResponse<Array<FluxInterface>>(fetch.get(`reference/flux/${clientId}`));
    }
}

export default new Flux();
