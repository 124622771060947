import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CurrencyInterface extends ReferenceInterface {
    cours: number;
    indices?: Array<any>;
}

class Currency {
    all(languageCode: string) {
        return parseResponse<Array<CurrencyInterface>>(fetch.get(`reference/currency/${languageCode}`));
    }
}

export default new Currency();
