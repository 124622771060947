import React, { useState } from "react";

interface ReadonlyContextProps {
    readonly: boolean;
}

interface ReadonlyContextInterface {
    readonly: boolean;
    setReadonly: (status: boolean) => void;
}

const ReadonlyContext = React.createContext<ReadonlyContextInterface>({} as ReadonlyContextInterface);

export const ReadonlyProvider: React.FC<ReadonlyContextProps> = ({ readonly, children }) => {
    const [isReadonly, changeReadonly] = useState<boolean>(readonly);

    return (
        <ReadonlyContext.Provider
            value={{
                readonly: isReadonly,
                setReadonly: (status: boolean) => {
                    changeReadonly(status);
                },
            }}
        >
            {children}
        </ReadonlyContext.Provider>
    );
};

const Consumer = ReadonlyContext.Consumer;

export { Consumer, ReadonlyProvider as Provider, ReadonlyContext as Context };
