import React from "react";

export interface TextareaProps
    extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    placeholder?: string;
    name: string;
}

const Textarea: React.FC<TextareaProps> = ({
    className = "field-control__textarea",
    autoComplete = "off",
    placeholder = " ",
    ...props
}) => {
    const textareaInput = React.useRef<HTMLTextAreaElement>(null);
    React.useEffect(() => {
        if (textareaInput && textareaInput.current) {
            const ta = textareaInput.current;
            const originalHeight = ta.style.height;
            ta.style.height = "";
            const endHeight = ta.scrollHeight;
            if (ta.scrollHeight === 0) {
                // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
                ta.style.height = originalHeight;
                return;
            }
            ta.style.height = endHeight + "px";
        }
    }, [props.value]);
    return (
        <textarea
            ref={textareaInput}
            className={className}
            placeholder={placeholder}
            autoComplete={autoComplete}
            {...props}
        />
    );
};

export default Textarea;
