import fetch, { parseResponse } from "../fetch";
import ReferenceInterface from "./ReferenceInterface";

export interface CategorieSocieteInterface extends ReferenceInterface {}

class CategorieSociete {
    all(languageCode: string) {
        return parseResponse<Array<CategorieSocieteInterface>>(
            fetch.get(`reference/categorie_societe/${languageCode}`)
        );
    }
}

export default new CategorieSociete();
