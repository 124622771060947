import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IntlContext } from "context";
import ErrorBoundary from "./components/ErrorBoundary";
import LogoMyDebt from "assets/images/logo-mydebt.png";
import LogoForex from "assets/images/logo-forex-vertical.png";

const Minimal: React.FC = ({ children }) => {
    const intl = useContext(IntlContext);

    return (
        <div className={"layout-minimal-root"}>
            <ErrorBoundary>
                <main className={"layout-minimal-main"}>
                    <div className={"layout-minimal-main-logo"}>
                        <Link to="/">
                            <img alt="" src={LogoMyDebt} />
                        </Link>
                    </div>

                    <div className={"layout-minimal-main-content margin-bottom--8"}>{children}</div>

                    <div className={"layout-minimal-main-copyright"}>
                        <p className="textalign--center">
                            <Link to="https://www.forexfinance.com/">
                                <img alt="" src={LogoForex} />
                            </Link>
                        </p>
                        <p>
                            {intl.formatMessage("generic.sidebar.copyright")}{" "}
                            {intl.formatMessage("generic.sidebar.date", { year: new Date().getFullYear() })}.
                        </p>
                    </div>
                </main>
                <div className={"layout-minimal-image"}></div>
            </ErrorBoundary>
        </div>
    );
};

export default Minimal;
