import React from "react";
import { ErrorInfo } from "react";

interface StateInterface {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<{}, StateInterface> {
    state = {
        hasError: false,
    };

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        //@TODO MULTI LANGUE
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <h2>Une erreur est survenue</h2>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
