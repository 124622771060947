import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export interface Options {
    value: string;
    label: React.ReactNode;
}

export interface CheckboxProps {
    id?: string;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    groupClassName?: string;
    labelClassName?: string;
    fields?: any;
    name: string;
    options: Array<Options>;
}

const Checkbox: React.FC<CheckboxProps> = ({ groupClassName, labelClassName, options, fields, ...props }) => {
    const toggle = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (event.target.checked) {
            fields.push(value);
        } else {
            fields.remove(fields.value.indexOf(value));
        }
    };

    return (
        <React.Fragment>
            {options.map((option) => {
                const id = `${props.name}_${option.value}`;
                const checked = fields?.value !== undefined && fields.value.includes(option.value);
                return (
                    <div className={groupClassName} key={option.value}>
                        <input
                            type="checkbox"
                            onChange={(event) => toggle(event, option.value)}
                            checked={checked}
                            value={option.value}
                            {...props}
                            id={id}
                        />
                        <label className={labelClassName} htmlFor={id}>
                            {option.label}
                        </label>
                        {checked && <Icon icon={{ prefix: "fab", iconName: "check" }} />}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

Checkbox.defaultProps = {
    className: "field-control-checkbox__input",
    groupClassName: "field-control-checkbox__group",
    labelClassName: "field-control-checkbox__label",
};

export default Checkbox;
