import React from "react";

export interface SelectInputProps extends React.HTMLProps<HTMLInputElement> {
    disabled?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {
    return <input {...props} />;
};

SelectInput.defaultProps = {
    className: "field-control-select__input",
};

export default SelectInput;
