import React from "react";
import BooleanCheckbox, { FormBooleanCheckboxProps } from "ui/Form/BooleanCheckbox";

import { Field, UseFieldConfig } from "react-final-form";

type Props = FormBooleanCheckboxProps & UseFieldConfig<string>;

const TextField: React.FC<Props> = ({
    labelProps,
    fieldGroupProps,
    fieldControlProps,
    inputProps,
    fieldControlClassName,
    ...fieldProps
}) => {
    return (
        <Field name={inputProps.name} type="checkbox" {...fieldProps}>
            {({ input, meta }) => {
                return (
                    <BooleanCheckbox
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.error || meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        fieldControlProps={fieldControlProps}
                        inputProps={Object.assign({}, input, inputProps)}
                        fieldControlClassName={fieldControlClassName}
                    />
                );
            }}
        </Field>
    );
};

export default TextField;
