import React, { useEffect, useState, useRef, CSSProperties } from "react";
import dataFetcher from "dataProvider/File";
import FileInterface from "dataProvider/FileInterface";

const DisplayLogo: React.FC<{
    id: string | undefined | null;
    alt?: string;
    height?: string;
    maxWidth?: string;
    className?: string;
}> = ({ id, alt, height, maxWidth, className }) => {
    const [logo, setLogo] = useState<FileInterface>({
        id: "",
        size: 0,
        content: new Blob(),
        name: "",
        type: "",
    });
    const ref: any = useRef();
    const logoId = logo.id;
    let style: CSSProperties = {};

    if (height) {
        style.height = height;
    }

    if (maxWidth) {
        style.maxWidth = maxWidth;
    }

    useEffect(() => {
        if (!id || logoId === id) {
            return;
        }

        const promise = dataFetcher.one({ id });
        promise
            .then((logo) => {
                setLogo(logo);
            })
            .catch((e) => {});

        return () => {
            promise.cancel();
        };
    }, [id, ref, logoId]);

    return (
        <React.Fragment>
            {id && logo.id !== "" && (
                <img
                    src={URL.createObjectURL(logo.content)}
                    alt={alt || logo.name || ""}
                    className={`img-responsive ${className}`}
                    style={style}
                />
            )}
        </React.Fragment>
    );
};

export default DisplayLogo;
