import React, { useCallback, useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import CountryService, { CountryInterface } from "dataProvider/Reference/Country";
import CurrencyService, { CurrencyInterface } from "dataProvider/Reference/Currency";
import LigneTypeService, { LigneTypeInterface } from "dataProvider/Reference/LigneType";
import ContrepartieService, { ContrepartieInterface } from "dataProvider/Reference/Contrepartie";
import BanqueService, { BanqueInterface } from "dataProvider/Reference/Banque";
import CategorieBanqueService, { CategorieBanqueInterface } from "dataProvider/Reference/CategorieBanque";
import CategorieSocieteService, { CategorieSocieteInterface } from "dataProvider/Reference/CategorieSociete";
import CleAnalytiqueService, { CleAnalytiqueInterface } from "dataProvider/Reference/CleAnalytique";
import CalendarService, { CalendarInterface } from "dataProvider/Reference/Calendar";
import IndiceService, { IndiceInterface } from "dataProvider/Reference/Indice";
import ActifService, { ActifInterface } from "dataProvider/Reference/Actif";
import RatioService, { RatioInterface } from "dataProvider/Reference/Ratio";
import FluxService, { FluxInterface } from "dataProvider/Reference/Flux";
import { ClientContext } from "context";
import * as constants from "./constants";

interface Options {
    value: string | number;
    label: string;
}

export interface getBaseReferenceReturn {
    country: CountryInterface;
    currency: CurrencyInterface;
    ligneType: LigneTypeInterface;
    categorieBanque: CategorieBanqueInterface;
    categorieSociete: CategorieSocieteInterface;
    calendar: CalendarInterface;
    banque: BanqueInterface;
    indice: IndiceInterface;
}

export interface getClientReferenceReturn {
    contrepartie: ContrepartieInterface;
    cleAnalytique: CleAnalytiqueInterface;
    actif: ActifInterface;
    ratio: RatioInterface;
    flux: FluxInterface;
}

export interface getReferenceReturn extends getBaseReferenceReturn, getClientReferenceReturn {}

export interface ReferencesProviderInterface {
    all<T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]>;
    selectList<T extends keyof getReferenceReturn>(type: T): Array<Options>;
    get<T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined;
    refreshData<T extends keyof getReferenceReturn>(type?: T): void;
    refreshClientData<T extends keyof getReferenceReturn>(type?: T): void;
    constants: HashMap<Array<string>>;
}

type BaseReferencesStateInterface = {
    [Key in keyof getBaseReferenceReturn]: Array<getBaseReferenceReturn[Key]>;
};

type ClientReferencesStateInterface = {
    [Key in keyof getClientReferenceReturn]: Array<getClientReferenceReturn[Key]>;
};

const ReferenceContext = React.createContext<ReferencesProviderInterface>({
    all: () => [],
    get: () => undefined,
    selectList: () => [],
    refreshData: () => null,
    refreshClientData: () => null,
    constants: {},
});

const Consumer = ReferenceContext.Consumer;

const Provider: React.FC = ({ children }) => {
    const { locale } = useIntl();
    const { currentClient } = useContext(ClientContext);

    const [references, setReferences] = useState<BaseReferencesStateInterface>({
        country: [],
        currency: [],
        ligneType: [],
        categorieBanque: [],
        categorieSociete: [],
        calendar: [],
        banque: [],
        indice: [],
    });

    const [clientReferences, setClientReferences] = useState<ClientReferencesStateInterface>({
        contrepartie: [],
        cleAnalytique: [],
        actif: [],
        ratio: [],
        flux: [],
    });

    const fetchReferences = useCallback(async (languageCode: string, type?: string) => {
        switch (type) {
            case "country":
                const country = await CountryService.all(languageCode);
                setReferences(Object.assign({}, references, { country }));
                break;
            case "currency":
                const currency = await CurrencyService.all(languageCode);
                setReferences(Object.assign({}, references, { currency }));
                break;
            case "ligneType":
                const ligneType = await LigneTypeService.all(languageCode);
                setReferences(Object.assign({}, references, { ligneType }));
                break;
            case "categorieBanque":
                const categorieBanque = await CategorieBanqueService.all(languageCode);
                setReferences(Object.assign({}, references, { categorieBanque }));
                break;
            case "categorieSociete":
                const categorieSociete = await CategorieSocieteService.all(languageCode);
                setReferences(Object.assign({}, references, { categorieSociete }));
                break;
            case "calendar":
                const calendar = await CalendarService.all(languageCode);
                setReferences(Object.assign({}, references, { calendar }));
                break;
            case "banque":
                const banque = await BanqueService.all();
                setReferences(Object.assign({}, references, { banque }));
                break;
            case "indice":
                const indice = await IndiceService.all();
                setReferences(Object.assign({}, references, { indice }));
                break;
            default:
                const countryService = await CountryService.all(languageCode);
                const currencyService = await CurrencyService.all(languageCode);
                const ligneTypeService = await LigneTypeService.all(languageCode);
                const categorieBanqueService = await CategorieBanqueService.all(languageCode);
                const categorieSocieteService = await CategorieSocieteService.all(languageCode);
                const calendarService = await CalendarService.all(languageCode);
                const banqueService = await BanqueService.all();
                const indiceService = await IndiceService.all();

                setReferences({
                    country: countryService,
                    currency: currencyService,
                    ligneType: ligneTypeService,
                    categorieBanque: categorieBanqueService,
                    categorieSociete: categorieSocieteService,
                    calendar: calendarService,
                    banque: banqueService,
                    indice: indiceService,
                });
                break;
        }
        // eslint-disable-next-line
    }, []);

    const fetchClientReferences = useCallback(async (clientId: string, type?: string) => {
        switch (type) {
            case "contrepartie":
                const contrepartie = await ContrepartieService.all(clientId);
                setClientReferences(Object.assign({}, clientReferences, { contrepartie }));
                break;
            case "cleAnalytique":
                const cleAnalytique = await CleAnalytiqueService.all(clientId);
                setClientReferences(Object.assign({}, clientReferences, { cleAnalytique }));
                break;
            case "actif":
                const actif = await ActifService.all(clientId);
                setClientReferences(Object.assign({}, clientReferences, { actif }));
                break;
            case "ratio":
                const ratio = await RatioService.all(clientId);
                setClientReferences(Object.assign({}, clientReferences, { ratio }));
                break;
            case "flux":
                const flux = await FluxService.all(clientId);
                setClientReferences(Object.assign({}, clientReferences, { flux }));
                break;
            default:
                const contrepartieService = await ContrepartieService.all(clientId);
                const cleAnalytiqueService = await CleAnalytiqueService.all(clientId);
                const actifService = await ActifService.all(clientId);
                const ratioService = await RatioService.all(clientId);
                const fluxService = await FluxService.all(clientId);

                setClientReferences({
                    contrepartie: contrepartieService,
                    cleAnalytique: cleAnalytiqueService,
                    actif: actifService,
                    ratio: ratioService,
                    flux: fluxService,
                });
                break;
        }
        // eslint-disable-next-line
    }, []);

    const refreshReference = useCallback(
        <T extends keyof getReferenceReturn>(type?: T): void => {
            fetchReferences(locale, type);
        },
        [fetchReferences, locale]
    );

    const refreshClientReference = useCallback(
        <T extends keyof getReferenceReturn>(type?: T): void => {
            if (currentClient) {
                fetchClientReferences(currentClient.id, type);
            }
        },
        [fetchClientReferences, currentClient]
    );

    const getReference = useCallback(
        <T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined => {
            const refs = type in clientReferences ? clientReferences : references;
            //@ts-ignore
            return refs[type].find((value) => {
                return code && value.code.toString() === code.toString();
            });
        },
        [references, clientReferences]
    );

    const allReferences = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]> => {
            //@ts-ignore
            return type in clientReferences ? clientReferences[type] : references[type];
        },
        [references, clientReferences]
    );

    const formatForSelect = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<Options> => {
            const refs = type in clientReferences ? clientReferences : references;
            //@ts-ignore
            return refs[type]
                .filter((value: any) => !value.deleted_at)
                .map((value: any) => {
                    return {
                        label: value.value !== undefined ? value.value : value.code,
                        value: value.code,
                    };
                });
        },
        [references, clientReferences]
    );

    // @TODO LOADER : usePromise ?
    useEffect(() => {
        if (currentClient && currentClient.id) {
            fetchClientReferences(currentClient.id);
        }
        fetchReferences(locale);
    }, [fetchReferences, locale, fetchClientReferences, currentClient]);

    return (
        <ReferenceContext.Provider
            value={{
                get: getReference,
                all: allReferences,
                selectList: formatForSelect,
                refreshData: refreshReference,
                refreshClientData: refreshClientReference,
                constants,
            }}
        >
            {children}
        </ReferenceContext.Provider>
    );
};

export { Provider, Consumer, ReferenceContext as Context };
