import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Logo from "assets/images/logo-mydebt.png";
import { AuthContext } from "context";
import SwitchAdmin from "./Switch/SwitchAdmin";
import SwitchClient from "./Switch/SwitchClient";
import MenuAdmin from "./Menu/MenuAdmin";
import MenuUser from "./Menu/MenuUser";

const Topbar: React.FC = () => {
    const auth = useContext(AuthContext);
    const match = useRouteMatch();
    const currentPageIsAdmin = match.path.startsWith("/admin/");

    return (
        <header className="heading-menu">
            <div className="appbar">
                <div className="appbar__logo">
                    <Link to="/">
                        <img alt="Logo" src={Logo} />
                    </Link>
                </div>
                <div className="appbar__toolbar">
                    <div className="appbar__toolbar-admin">{auth.isAllowed("admin.*") && <SwitchAdmin />}</div>
                    <div className="appbar__toolbar-user">
                        {currentPageIsAdmin && <MenuAdmin />}
                        {!currentPageIsAdmin && <SwitchClient />}
                        <MenuUser />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Topbar;
