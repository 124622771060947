import React, { useContext } from "react";
import { IntlContext } from "context";

export const getTranslateKeyFromErrorCode = (code: string) => {
    return `error.${code}`;
};

const ErrorMessage: React.FC<{ error: any }> = ({ error }) => {
    const intl = useContext(IntlContext);

    if (error && error.code) {
        return intl.formatMessage(getTranslateKeyFromErrorCode(error.code), error.params);
    }

    return error;
};

export default ErrorMessage;
