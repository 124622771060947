import React from "react";
import FieldGroup, { FieldGroupProps } from "./Element/FieldGroup";
import FieldControl, { FieldControlProps } from "./Element/FieldControl";
import Radio, { RadioProps } from "./Element/Radio";
import Label, { LabelProps } from "./Element/Label";
import Feedback from "./Element/Feedback";

export interface FormRadioProps {
    error?: boolean;
    errorMessage?: string;
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: RadioProps;
    fieldGroupProps?: FieldGroupProps;
    fieldControlProps?: FieldControlProps;
}

const FormRadio: React.FC<FormRadioProps> = ({
    error,
    errorMessage,
    labelProps,
    inputProps,
    fieldGroupProps,
    fieldControlProps,
}) => {
    const disabled = inputProps.disabled;
    const required = inputProps.required;
    return (
        <FieldGroup error={error} {...fieldGroupProps}>
            <FieldControl className={"field-control-radio"} error={error} disabled={disabled} {...fieldControlProps}>
                <Radio disabled={disabled} {...inputProps} />
            </FieldControl>
            {labelProps !== undefined && (
                <Label htmlFor={inputProps.id} disabled={disabled} error={error} required={required} {...labelProps}>
                    {labelProps.label}
                </Label>
            )}
            {error && errorMessage !== undefined && (
                <Feedback className="field__feedback error">{errorMessage}</Feedback>
            )}
        </FieldGroup>
    );
};

FormRadio.defaultProps = {
    error: false,
};
export default FormRadio;
