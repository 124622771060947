import React, { useContext } from "react";
import { IntlContext } from "context";
import { Backdrop, Loader } from "ui/Loader";

const AnalyseLoader: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <div className="container">
            <Backdrop>
                <Loader message={intl.formatMessage("generic.loader.loading.label")} />
            </Backdrop>
        </div>
    );
};

export default AnalyseLoader;
