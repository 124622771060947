import React from "react";

export interface AdornmentProps {
    className?: string;
    position: "start" | "end";
}

const Adornment: React.FC<AdornmentProps> = ({ children, className = "field-control__adornment", position }) => {
    return (
        <div className={`${className} ${position === "start" ? className + "--start" : className + "--end"}`}>
            {children}
        </div>
    );
};

export default Adornment;
