import React from "react";
import { UseFieldConfig } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FieldRepeater, { FieldRepeaterProps } from "ui/Form/Repeater";

type Props = FieldRepeaterProps & UseFieldConfig<any[]>;

const RepeaterField: React.FC<Props> = ({ labelProps, fieldGroupProps, inputProps, ...fieldProps }) => {
    return (
        <FieldArray name={inputProps.name} {...fieldProps}>
            {({ fields, meta }) => {
                return (
                    <FieldRepeater
                        fields={fields}
                        error={!!(meta.error || meta.submitError)}
                        errorMessage={meta.submitError}
                        labelProps={labelProps}
                        fieldGroupProps={fieldGroupProps}
                        inputProps={Object.assign({}, inputProps)}
                    />
                );
            }}
        </FieldArray>
    );
};

export default RepeaterField;
